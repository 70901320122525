header#header {
	box-shadow: 0 4px 4px rgba(#000, 0.3);
	z-index: 999;
	position: relative;
}
.supheader {
	color: #fff;
	background-color: #101417;
	text-transform: uppercase;
	font-size: 11px;
	text-align: center;
    @include media-breakpoint-down('sm') {
        padding-top: 6px;
    }
	.row {
		align-items: center;
        [class*='col-']:first-child  {
            @include media-breakpoint-up('md') {
                text-align: left;
            }

            a {
                text-decoration: underline;
                &:hover {
                    color: $bleu-c;
                }
            }
        }
        [class*='col-']:last-child  {
            @include media-breakpoint-up('md') {
                text-align: right; 
                p:first-child {
                    margin-right: 16px;
                }
            }
        }
	}
	p {
		display: inline-block;
		padding: 0;
		margin: 0;
	}
	a {
		color: #fff;
		&:hover {
			color: #fff;
		}
		&:active {
			color: #fff;
		}
	}
	.facebook,
	.instagram {
		margin-top: 8px;
		margin-bottom: 8px;
	}
	.facebook {
		width: auto;
		height: 12px;
		margin-left: 12px;
	}
	.instagram {
		width: auto;
		height: 20px;
	}
}
.midheader {
    position: relative;
	
	.align-items-center {
		@include media-breakpoint-between(sm, md) {
			justify-content: space-between;
			
			.offset-md-4 {
				margin-left: 0% !important;;
			}
			._desktop_logo {
				
			}
			.col-md-4:nth-child(2) {
				display: flex;
				justify-content: flex-end;
			}
		}
	}
}

.primary-nav {
	ul {
		@extend %reset-ul;
		li {
			display: inline-block;
			a {
				color: #000;
				transition: color 300ms ease;
				&:hover {
					color: #333;
				}

				svg {
					width: 14px;
					height: 19px;
					margin-bottom: 0.2rem;
				}
			}
		}
	}
}

div#_desktop_contact_link {
	display: none !important;
}

.desktop_logo {
	padding: 10px 0;
	position: relative;
}

.desktop_pictos {
	padding: 10px 0;
	a {
		line-height: 73px;
		&::before {
			top: 5px;
		}
	}
}

.breadcrumb {
	position: absolute;
	z-index: 998;
	@include make-row-full;
	//z-index: 1;
	.bg-breadcrumb {
		mix-blend-mode: multiply;
		background-color: rgba(#294e6b, 0.6);
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: -1;
		height: 100%;
	}
	ol {
		@extend .container;
		text-align: center;
		margin: 0 auto;
		padding: 0.6rem 0 0.6rem 0;
		li {
			display: inline-block;
			span,
			a {
				color: white;
				text-transform: uppercase;
				font-family: $ff-secondary;
				font-size: 13px;
			}
			a:hover {
				color: $bleu-c;
			}
		}
	}
	.bg-breadcrumb {
		position: absolute;
		top: 0;
		left: 0;
	}
}

a.top_facebook {
	height: 16px;
	width: 16px;
	margin: 0px 3px;
	background-image: url(../img/picto-facebook.png);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	display: inline-block;
	background-size: 7px;
}
a.top_instagram {
	height: 16px;
	width: 16px;
	margin: 0px 3px;
	background-image: url(../img/picto-instagram.png);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	display: inline-block;
	background-size: 10px;
}
a.top_twitter {
	height: 16px;
	width: 16px;
	margin: 0px 3px;
	background-image: url(../img/picto-twitter.png);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	display: inline-block;
	background-size: 10px;
}