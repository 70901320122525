// hero
.hero {
	background: url("../img/gold_scale.png");
	position: relative;
	padding-top: 6rem;
	padding-bottom: 6rem;
	background-size: 40px;
	text-align: center;
	z-index: 1;
	margin-bottom: 3rem;

	@include media-breakpoint-up("lg") {
		padding-top: 8rem;
		padding-bottom: 8rem;
	}

	h1 {
		font-size: 25px;
		text-transform: uppercase;
		font-family: $ff-secondary;
		color: white;
		text-shadow: 0 0 8px #000;

		@include media-breakpoint-up("lg") {
			font-size: 33px;
		}
	}

	&::after {
		content: "";
		background: url("../img/vague-blanche-after.png") no-repeat bottom center / contain;
		position: absolute;
		height: 120px;
		width: 100%;
		left: 0;
		bottom: 0px;
		z-index: 1;
	}

	.bgc {
		z-index: -1;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-color: #1a345a;
		opacity: 0.95;
		mix-blend-mode: multiply;
	}
}

main>#wrapper {
	position: relative;
	z-index: 2;

	.breadcrumb {
		top: 0;

		a {
			span {
				color: $bleu-c;
			}
		}

		li:not(:last-child):not(:only-child) {
			&:after {
				content: ">";
				color: white;
			}
		}
	}
}

#hero-search {
	background: url("../img/gold_scale.png");
	position: relative;
	padding-top: 6rem;
	padding-bottom: 6rem;
	background-size: 40px;
	text-align: center;
	z-index: 1;
	margin-bottom: 3rem;

	@include media-breakpoint-up("lg") {
		padding-top: 8rem;
		padding-bottom: 8rem;
	}

	h1 {
		font-size: 25px;
		text-transform: uppercase;
		font-family: $ff-secondary;
		color: white;
		text-shadow: 0 0 8px #000;

		@include media-breakpoint-up("lg") {
			font-size: 33px;
		}
	}

	&::before {
		content: '';
		z-index: -1;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-color: #1a345a;
		opacity: 0.90;
		mix-blend-mode: multiply;
		display: block !important;
	}

	&::after {
		content: "";
		background: url("../img/vague-blanche-after.png") no-repeat bottom center / contain;
		position: absolute;
		height: 120px;
		width: 100%;
		left: 0;
		bottom: 0px;
		z-index: 1;
	}
}


#hero-a-propos,
.hero-image {
	background-size: cover;
	background-position: 50% 50%;
	margin-bottom: 0 !important;

	>.wpb_column {
		float: none !important;
		position: unset !important;

		>.vc_column-inner {
			height: 300px;
		}
	}

	.contenu-titre {
		margin-bottom: 0 !important;
		padding: 1rem 0;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0.5);

		>.wpb_wrapper>* {
			text-align: center;
			font-size: 25px;
			color: white;
			font-family: $ff-secondary;
			text-align: center;
			font-weight: 400;
			text-transform: uppercase;
			line-height: 1.2;

			@include media-breakpoint-up("lg") {
				font-size: 35px;
			}
		}
	}
}

.vagueimgTop {
	img {
		@include media-breakpoint-down("md") {
			margin-top: -13px;
		}
	}
}

.vagueimgBot {
	img {
		@include media-breakpoint-down("md") {
			margin-bottom: -7px;
		}
	}
}