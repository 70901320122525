.alert {
    margin-left: -15px;
    ul {
        list-style-type: none;
        margin: 0.625rem 0;
        padding: 0;
    }

    &.alert-success {
        li {
            background-color: #2ecc71;
            color: white;
            display: inline-block;
            padding: 4px 8px;
            border-radius: 4px;
        }
    }

    &.alert-danger {
        li {
            background-color: #e74c3c;
            color: white;
        }
    }
}