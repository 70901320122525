#product {
	main #wrapper #main {
		padding-top: 5rem;
	}
}

// product add to cart default
.product-add-to-cart {
	.label-quantity-group {
		display: flex;
		align-items: center;

		.control-label {
			margin-right: 12px;
			font-size: 16px;
			font-weight: 600;
		}

		.qty {
			.input-group {
				position: relative;
				height: 40px;
				width: 160px;
			}

			#quantity_wanted {
				position: absolute;
				appearance: textfield;
				border: none;
				width: calc(100% - 80px);
				left: 40px;
				padding-left: 10px;
				padding-right: 10px;
				text-align: center;
			}

			.input-group-btn-vertical {
				position: absolute;
				width: 100%;
				height: 40px;
				top: 0;

				button {
					background-color: #00b2bf;
					border: none;
					position: absolute;
					top: 0;
					width: 40px;
					height: 40px;
					right: 0;
					left: auto;
					@include transitionBg;

					&::before {
						content: "+";
						font-weight: 700;
						color: white;
						font-size: 26px;
					}

					&.bootstrap-touchspin-down {
						left: 0;
						right: auto;

						&::before {
							content: "-";
						}
					}

					&:hover {
						background-color: $bleu-uf;
					}
				}
			}
		}
	}
}

.btn.add-to-cart {
	background-color: #00b2bf;
	border: none;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	padding: 0.8rem 1rem 0.8rem 3.8rem;
	border-radius: 12px;
	color: white;
	font-weight: 700;
	justify-content: space-around;
	position: relative;
	transition: background-color 300ms ease;

	>.icon {
		position: absolute;
		width: 40px;
		height: 40px;
		left: 0.8rem;
		background: url("../img/add-to-cart.svg") no-repeat 50% 50% / 25px;
		display: block;
	}

	>.hover {
		position: absolute;
		display: block;
		width: 40px;
		height: 40px;
		position: absolute;
		left: 0.8rem;
		background-color: #fff;
		border-radius: 50%;
		overflow: hidden;
		transition: background-color 500ms ease;

		&::after {
			content: "";
			background: url("../img/bg-wave-2.svg") repeat-x;
			background-size: 40px;
			transform: scaleY(0);
			transition: transform 300ms ease, left 1200ms ease;
			transform-origin: bottom;
			position: absolute;
			bottom: -5px;
			left: 0;
			width: 160px;
			height: 100%;
		}
	}

	&:hover {
		background-color: $bleu-uf;

		>.hover {

			//background-color: #3ca3bf;
			&::after {
				transform: scaleY(0.8);
				left: -200%;
			}
		}
	}

	&:active {
		>.hover {
			&::after {
				left: -300%;
			}
		}
	}
}

#product {
	#wrapper {
		background-color: #f4f4f4;
	}

	.product-container {

		// left
		.product-cover {
			overflow: hidden;
			position: relative;
			padding-left: 32px;
			padding-right: 32px;
			background-color: #fff;
			box-shadow: 6px 6px 6px rgba(#000, 0.3);
			margin-bottom: 2rem;

			@include media-breakpoint-up("lg") {
				height: 480px;
			}

			.product-flags {
				position: absolute;
				top: 20px;
				left: 20px;
				display: block;
				list-style-type: none;
				margin: 0;
				padding: 0;

				li {
					padding: 2px 20px;
					display: inline-block;
					font-size: 14px;
					border-radius: 35px;

					&:not(:last-child) {
						margin-right: 8px;
					}

					&.new {
						background-color: #e5bb07;
						color: white;
						text-transform: uppercase;
						font-family: $ff-secondary;
					}
				}
			}
		}

		.js-qv-mask {
			ul {
				list-style-type: none;
				margin: 0;
				padding: 0;
			}

			.product-images {
				li {
					display: inline-block;

					&:not(:last-child) {
						margin-right: 8px;
					}
				}
			}
		}

		.scroll-box-arrows {
			display: none;
		}

		//right
		.h1 {
			font-size: 33px;
			font-weight: 700;
		}

		.product-prices {
			>div {
				font-size: 30px;
				font-weight: 700;
			}

			.product-price {
				display: table-cell;
				vertical-align: middle;
				padding-right: 8px;
			}
		}

		.product-information {
			padding-top: 1rem;

			.product-add-to-cart {
				width: 240px;

				.add {
					margin-top: 1rem;

					.btn {
						width: 100%;
					}
				}

				.availability {
					width: 100%;
					text-align: center;
				}
			}
		}

		.social-sharing {
			display: flex;
			align-items: center;
			margin-bottom: 1.5rem;

			>span {
				margin-right: 8px;
			}

			ul {
				list-style-type: none;
				margin: 0;
				padding: 0;
				display: flex;
				align-items: center;

				li {
					display: inline-block;

					&:not(:last-child) {
						margin-right: 12px;
					}

					a {
						border-radius: 50%;
						width: 25px;
						height: 25px;
						display: block;
						background-size: 20px;
						background-repeat: no-repeat;
						background-position: 50% 50%;

						&:hover {
							background-color: $bleu-c;
						}
					}
				}

				li.facebook {
					a {
						background-image: url("../img/facebook.svg");
					}
				}

				li.twitter {
					a {
						background-image: url("../img/twitter.svg");
					}
				}

				li.pinterest {
					a {
						background-image: url("../img/pinterest.svg");
					}
				}
			}
		}

		.list-reassurance {
			list-style-type: none;
			display: flex;
			justify-content: space-between;
			margin: 0;
			padding: 0;
			border-top: 2px solid #000;
			padding-top: 1rem;

			li {
				display: flex;
				align-items: center;
				justify-content: center;

				@include media-breakpoint-up("lg") {
					justify-content: flex-start;
					align-items: flex-end;
				}

				img {
					width: 20px;
					display: table-cell;
					margin-right: 12px;

					@include media-breakpoint-up("lg") {
						width: 40px;
					}
				}

				&:first-child {
					img {
						width: 20px;

						@include media-breakpoint-up("lg") {
							width: 50px;
						}
					}
				}

				p {
					line-height: 1;
					text-transform: uppercase;
					font-family: "Oswald";
					color: $bleu-n;
					margin-bottom: 0;
				}
			}
		}
	}

	.tabs {
		margin-top: 2rem;

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			position: relative;
			z-index: 1;

			&::after {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				height: 2px;
				width: 100%;
				background-color: #808080;
				z-index: -1;
			}

			li {
				display: inline-block;

				a {
					text-transform: uppercase;
					font-size: 20px;
					font-family: $ff-secondary;
					display: block;
					padding: 20px 43px;
					color: $bleu-n;

					&.active {
						border-bottom: 2px solid #00b2bf;
					}

					&:hover {
						border-bottom: 2px solid #00b2bf;
					}
				}
			}
		}

		#tab-content {
			padding-top: 2rem;
			padding-bottom: 2rem;
			position: relative;

			.tab-pane {
				height: 0;
				opacity: 0;
				visibility: hidden;
				transition: opacity 300ms ease;

				&.active {
					opacity: 1;
					visibility: visible;
					height: auto;
				}
			}

			#description {
				strong {
					text-transform: uppercase;
					color: #00b2bf;
					display: block;
				}
			}
		}
	}
}

.availability {
	display: inline-block;
	padding: 8px 30px;
	border-radius: 8px;
	color: white;
	font-weight: 700;
	margin-top: 1rem;
	text-transform: uppercase;

	&.available {
		background-color: #8ad137;
	}

	&.unavailable {
		background-color: red;
	}

	&.on-demand {
		background-color: #fca10e;
	}

	&.last_remaining_items {
		background-color: #e5bb07;
	}
}

#featured-category-products-block-center {
	padding-bottom: 4rem;

	h2 {
		font-family: $ff-secondary;
		font-size: 30px;
		text-transform: uppercase;
		padding-left: 37px;
		position: relative;
		display: table;
		margin-left: 0;
		margin-right: auto;
		margin-bottom: 2rem;

		&::before {
			content: "";
			background: url("../img/picto-ceres.png") no-repeat 50% 50% / contain;
			width: 29px;
			height: 37px;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	ul {
		margin-bottom: 0;
		list-style-type: none;
		padding: 0;
	}

	.products.row {
		>div {
			@include make-col-ready();

			@include media-breakpoint-up("md") {
				@include make-col(4);
			}
		}
	}
}

.product-variants {
	padding-bottom: 2rem;

	.control-label {
		text-transform: uppercase;
		font-weight: 700;
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
		padding-top: 1rem;

		li {
			display: inline-block;

			&:not(:last-child) {
				margin-right: 10px;
			}
		}
	}
}