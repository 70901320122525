#search_widget {
    margin-bottom: 3rem;
	form {
		text-align: center;
	}
	button {
		@extend %btn-default;
        > span {
            @include media-breakpoint-down('sm') {
                display: none;
            }
        }
	}
	input[type="text"] {
        background-color: transparent;
        width: 240px;
		@include media-breakpoint-up("lg") {
			width: 300px;
		}
	}
}
