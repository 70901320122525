$bleu-c:    #5ebfcf;
$bleu-m:    #3CA4C0;
$bleu-f:    #217182;
$bleu-uf:   #1a345a;
$bleu-n: #101416;
$orange:    #fca10e;

$ff-primary: 'Lato', sans-serif;
$ff-secondary: 'Aleo', serif;
$ff-tertiary: 'Courgette', serif;

$gris8: #888888;

$beige: #ebeae6;

%font-family-base {
    font-family: "Poppins", sans-serif;
}
%absolute-vertical-align {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

%absolute-horizontal-align {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

%absolute-both-align {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

@mixin transitionBg() {
    transition: background-color 300ms ease;
}