[data-action="show-password"] {
	display: inline-block;
	text-indent: -999px;
	background: url("../img/show-passwd.svg") no-repeat 50% 50% / contain;
	border: none;
	overflow: hidden;
	position: absolute;
	width: 20px;
	height: 15px;
	right: 0;
	padding: 0;
	margin: 0;
	bottom: 20px;
	@include media-breakpoint-up("sm") {
		width: 20px;
		height: 20px;
		bottom: 4px;
	}
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"] {
	border: none;
	border-bottom: 2px solid #405069;
	border-radius: 0;
	@include media-breakpoint-down("sm") {
		margin-bottom: 0.75rem;
	}
}

input[type="password"] {
	width: 100%;
}

.form-control-comment {
	font-size: 12px;
	display: block;
	margin-top: 0.5rem;
	font-style: italic;
}
.input-group {
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;
}

.form-group .custom-checkbox {
	position: relative;
	label {
		padding-left: 25px;
		position: relative;
		> span {
			height: 20px;
			width: 20px;
			position: absolute;
			border: 2px solid #eee;
			display: inline-block;
			left: 0;
			top: 0;
			i {
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				font-size: 16px;
				color: $bleu-m;
			}
		}
		font-size: 12px;
	}
	input[type="checkbox"] {
		display: none;
		&:checked ~ span i {
			opacity: 1;
		}
	}
}
.form-group {
	.form-control-label {
		text-transform: uppercase;
		font-size: 13px;
		font-family: $ff-secondary;
		color: $bleu-m;
		margin-bottom: 0;
	}
}

// page contact
#wpcf7-f2-o1 form {
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
	input:not([type="checkbox"]):not([type="submit"]),
	textarea {
		width: 100%;
	}
	textarea {
		border: none;
		border-bottom: 2px solid #405069;
		border-radius: 0;
	}
	h3 {
		font-family: $ff-secondary;
		font-size: 25px;
		text-transform: uppercase;
		padding-left: 45px;
		position: relative;
		display: table;
		margin-left: auto;
		margin-right: auto;
		margin-top: 2rem;
		margin-bottom: 1rem;
		@include media-breakpoint-up("lg") {
			font-size: 30px;
		}
		&::before {
			content: "";
			background: url("../img/picto-ceres.png") no-repeat 50% 50% / contain;
			width: 29px;
			height: 37px;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	.label-vous-etes {
		text-transform: uppercase;
		font-family: "Oswald";
		color: #3ca3bf;
		font-size: 18px;
	}
    input[type="submit"] {
        @extend %btn-default;
        display: inline-block;
    }
    .acceptance {
        span {
            display: inline-block;
            &.required-fields {
                font-size: 13px;
                font-weight: 500;
                margin-right: 16px;
            }
        }
    }
    .submit {
        text-align: right;
    }
}
