#checkout {
	$bleu-cart: #00b2bf;
	.cart-grid-body {
		.checkout-step {
			background-color: #fff;
			border-top: 4px solid $bleu-cart;
			border-bottom: 1px solid $bleu-cart;
			border-left: 1px solid $bleu-cart;
			border-right: 1px solid $bleu-cart;
			overflow: hidden;
			margin-bottom: 2rem;
			padding: 2rem 1rem;
			@include media-breakpoint-up("md") {
				padding: 2rem;
			}
			button.continue {
				@extend %btn-default;
			}
			.js-cancel-address.cancel-address {
				@extend %btn-default;
				background-color: $bleu-n;
				margin-left: 8px;
			}
			footer.form-footer {
				text-align: right;
			}

			.content {
				overflow: hidden;
			}
			.step-title {
				font-family: $ff-secondary;
				font-size: 18px;
				text-transform: uppercase;
				padding-left: 47px;
				position: relative;
				display: flex;
				align-items: center;
				margin-left: 0;
				margin-right: auto;
				//margin-bottom: 2rem;
				&::before {
					content: "";
					background: url("../img/picto-ceres.png") no-repeat 50% 50% / contain;
					width: 29px;
					height: 37px;
					position: absolute;
					top: 0;
					left: 0;
				}
				.done {
					display: none;
				}

				@include media-breakpoint-up("md") {
					font-size: 25px;
				}
			}
			.step-edit {
				text-transform: none;
				font-size: 14px;
				display: inline-block;
				margin-left: auto;
				min-width: 80px;
				i {
					font-size: 14px;
					text-align: right;
				}
			}

			&.-unreachable {
				.content {
					height: 0;
				}
			}

			// complete
			&.-complete {
				.content {
					height: 0;
				}
			}
			&.-current {
				.content {
					height: auto;
				}
			}
		}

		// informations
		#checkout-personal-information-step {
			ul.nav.nav-inline {
				list-style-type: none;
				margin: 0;
				padding: 0;
				text-align: center;
				padding-top: 1.5rem;
				padding-bottom: 1.5rem;
				li {
					display: inline-block;
					.nav-link {
						color: $bleu-cart;
						display: block;
						&:first-child {
							padding-right: 1rem;
						}
						&:last-child {
							padding-left: 1rem;
							padding-right: 1rem;
						}
					}

					&:nth-child(2) {
						@include media-breakpoint-down("sm") {
							display: none;
						}
					}
				}
			}
			.tab-content {
				position: relative;
				.tab-pane {
					position: absolute;
					top: 0;
					left: 0;
					height: 0;
					overflow: hidden;
					&.active {
						position: relative;
						height: auto;
					}
				}
			}
		}
	}
	#checkout-personal-information-step {
		.content {
			.tab-content {
				position: relative;
				> .tab-pane {
					position: absolute;
					height: 0;
					overflow: hidden;
					&.active {
						position: relative;
						height: 100%;
					}
				}

				#checkout-guest-form,
				#checkout-login-form {
					#customer-form,
					#login-form {
						> section {
							@include make-row();
							.form-group {
								@include make-col-ready();
								margin-bottom: 0.625rem;
								input.form-control {
									width: calc(100% - 4px);
									margin: 2px;
								}
								@include media-breakpoint-up("md") {
									@include make-col(6);
									&:first-child {
										@include make-col(12);
									}
								}
							}
							.forgot-password {
								@include make-col-ready;
								a {
									color: $bleu-cart;
									font-size: 14px;
								}
							}
							p {
								@include make-col-ready();
								margin-top: 1rem;
								~ .form-group:not(.checkboxes) {
									@include make-col(12);
								}
							}
						}
					}
				}
			}
		}
	}
	#checkout-addresses-step {
		.js-address-form {
			padding-top: 1rem;
			.radio-block {
				.custom-radio {
					transform: translateY(-4px);
					display: inline-block;
				}
				.address-alias {
					font-weight: 700;
					font-size: 18px;
					color: $bleu-cart;
					display: inline-block;
				}
				.address {
					padding-top: 1rem;
					margin-top: 1rem;
					border-top: 1px solid #ccc;
					width: 100%;
				}
			}
			footer.address-footer {
				i {
					font-size: 13px;
				}
				a:hover {
					color: $orange !important;
				}
			}
			a {
				color: $bleu-cart;
				&:hover {
					color: $bleu-uf;
				}
				i {
					font-size: 13px;
				}
			}
		}
	}
	#delivery-address {
		> .js-address-form .form-fields {
			@include make-row();
			.form-group {
				@include make-col-ready();
				@include media-breakpoint-up("md") {
					@include make-col(6);
					margin-bottom: 0.625rem;
				}
			}
			.form-group:nth-child(3) {
				@include make-col(12);
			}
		}
	}
	#invoice-address {
		.form-fields {
			@include make-row();
		}
		.form-group {
			@include make-col-ready();
			@include make-col(12);
			margin-bottom: 0.625rem;
            @include media-breakpoint-up('md') {
                &:not(:first-child) {
                    @include make-col(6);
                }
            }
			input {
				width: 100%;
			}
		}
	}
	.row.delivery-option {
		align-items: center;
		padding-top: 1rem;
		padding-bottom: 1rem;
		.delivery-option-2 .row {
			align-items: center;
		}
	}
	#delivery {
		margin-bottom: 1rem;
		textarea {
			max-width: 100%;
			height: 180px;
		}
	}

	#checkout-payment-step {
		#conditions-to-approve {
			ul {
				list-style-type: none;
				margin: 0;
				padding: 0;
				li {
					display: flex;
					align-items: center;
					.custom-checkbox {
						position: relative;
						width: 20px;
						height: 20px;
						display: block;
						input {
							display: none !important;
						}
						span {
							width: 20px;
							height: 20px;
							border: 2px solid #eee;
							position: absolute;
							top: 0;
							display: block;
							z-index: 1;
							i {
								position: absolute;
								top: 0;
								left: 0;
								font-size: 15px;
								color: $bleu-cart;
								opacity: 0;
								visibility: hidden;
								display: none;
							}
						}
						input:checked {
							~ span {
								i {
									opacity: 1;
									visibility: visible;
									display: block;
								}
							}
						}
					}
					.float-xs-left {
						position: absolute;
						width: 20px;
						height: 20px;
					}
					.condition-label {
						.js-terms {
							padding-left: 30px;
							position: relative;
							z-index: 999;
						}
						a {
							color: $bleu-m;
						}
					}
				}
			}
		}
	}
	#payment-confirmation {
		text-align: center;
		padding-top: 1rem;
		.btn {
			@extend %btn-default;
		}
	}

	.cart-grid-right {
		> .box-shadow {
			box-shadow: 4px 4px 7px rgba($color: #000000, $alpha: 0.4);
			padding: 2rem 1rem;
			h2 {
				font-family: $ff-secondary;
				font-size: 25px;
				text-transform: uppercase;
				padding-left: 47px;
				position: relative;
				display: flex;
				align-items: center;
				margin-left: 0;
				margin-right: auto;
				margin-bottom: 2rem;
				&::before {
					content: "";
					background: url("../img/picto-ceres.png") no-repeat 50% 50% / contain;
					width: 29px;
					height: 37px;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
			#cart-summary-product-list {
				&.collapse {
					height: 0;
					overflow: hidden;
				}
				&.collapse.in {
					height: auto;
				}
			}
			.cart-summary-products {
				p:first-child {
					font-weight: 700;
					&::after {
						content: " : ";
					}
				}
				a[data-toggle="collapse"] {
					color: $bleu-m;
					line-height: 16px;
					display: flex;
					align-items: center;
					i {
						font-size: 16px;
						line-height: 16px;
						margin-left: auto;
					}
				}
				ul.media-list {
					list-style-type: none;
					margin: 0;
					padding: 0;
					.media {
						display: flex;
						align-items: center;
						margin-bottom: 1rem;
					}
					.media-left {
						width: 30%;
						margin-right: 16px;
						border: 2px solid #eee;
					}
					.media-body {
						width: 70%;
						.product-price {
							display: block;
							color: $bleu-m;
							font-weight: 700;
							font-size: 18px;
						}
						.product-quantity {
							color: $bleu-m;
							font-weight: 700;
						}
					}
				}
			}
			#cart-subtotal-shipping {
				background-color: #dfeded;
			}
			.cart-summary-line {
				margin-left: -1rem;
				margin-right: -1rem;
				padding: 0.625rem;
				display: flex;
				.value {
					margin-left: auto;
				}
			}
		}
		> .secure-paiement {
			display: flex;
			align-items: center;
			text-transform: uppercase;
			font-family: "Oswald";
			color: $bleu-f;
			font-size: 18px;
			padding: 2rem;
			img {
				width: 90px;
				margin-right: 16px;
			}
		}
	}
}

#order-confirmation .hero {
	@include make-row-full;
}
$bleu-cart: #00b2bf;
#content-hook_order_confirmation,
#content.card:not(.page-content) {
	margin-top: 2rem;
	background-color: #fff;
	border-top: 4px solid $bleu-cart;
	border-bottom: 1px solid $bleu-cart;
	border-left: 1px solid $bleu-cart;
	border-right: 1px solid $bleu-cart;
	padding: 2rem;
	overflow: hidden;
	margin-bottom: 2rem;
	.card-title:not(._desktop-title) {
		font-family: $ff-secondary;
		font-size: 25px;
		text-transform: uppercase;
		padding-left: 47px;
		position: relative;
		display: flex;
		align-items: center;
		margin-left: 0;
		//margin-right: auto;
		margin-bottom: 2rem;
		width: 100%;
		&::before {
			content: "";
			background: url("../img/picto-ceres.png") no-repeat 50% 50% / contain;
			width: 29px;
			height: 37px;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.order-confirmation-table {
		.order-line {
			align-items: center;
			justify-content: space-between;
			.image {
				display: block;
				border: 2px solid #eee;
			}
			.bold {
				color: $bleu-cart;
				font-weight: 700;
			}
		}
	}
}
#order-confirmation {
	#order-items {
		hr {
			color: $bleu-cart;
			background-color: $bleu-cart;
			opacity: 1;
			margin-bottom: 1rem;
		}
	}
	#order-details {
		border-left: 2px solid $bleu-cart;
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			li {
				margin-bottom: 1rem;
			}
		}
	}

    .page-order-confirmation {
        .order-line {

            margin-bottom: 1rem;
        }
    }
}
