#cart #wrapper {
	background-color: #f4f4f4;

	#main {
		padding-top: 5rem;
		padding-bottom: 4rem;
	}
}

.card.cart-container {
	$bleu-cart: #00b2bf;
	background-color: #fff;
	border-top: 4px solid $bleu-cart;
	border-bottom: 1px solid $bleu-cart;
	border-left: 1px solid $bleu-cart;
	border-right: 1px solid $bleu-cart;
	padding: 2rem;

	h1 {
		font-family: $ff-secondary;
		font-size: 25px;
		text-transform: uppercase;
		padding-left: 47px;
		position: relative;
		display: table;
		margin-left: 0;
		margin-right: auto;
		margin-bottom: 1rem;

		&::before {
			content: "";
			background: url("../img/picto-ceres.png") no-repeat 50% 50% / contain;
			width: 29px;
			height: 37px;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.cart-items {
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			border-bottom: 2px solid $bleu-cart;
			padding-bottom: 12px;
			margin-bottom: 12px;

			.product-line-grid {
				.product-line-grid-body {
					margin-bottom: 1rem;

					.product-line-info {
						a {
							color: $bleu-cart;
							font-weight: 700;
						}
					}

					.product-price {
						font-size: 14px;
					}

					.unit-price-cart {
						display: inline-block;
					}

					@include media-breakpoint-up("sm") {
						margin-bottom: 0;

						a {
							font-size: 18px;
						}
					}
				}

				@include make-row();
				align-items: center;

				.row {
					align-items: center;
				}

				.qty {
					.input-group {
						position: relative;
						height: 40px;
						width: 110px;

						.js-cart-line-product-quantity {
							position: absolute;
							appearance: textfield;
							border: none;
							width: calc(100% - 60px);
							height: 100%;
							left: 30px;
							padding-left: 10px;
							padding-right: 10px;
							text-align: center;
							top: 0;
						}

						.input-group-btn-vertical {
							position: absolute;
							width: 100%;
							top: 0;

							button {
								background-color: #00b2bf;
								border: none;
								position: absolute;
								top: 0;
								width: 30px;
								height: 40px;
								right: 0;
								left: auto;

								&::before {
									content: "+";
									font-weight: 700;
									color: white;
									font-size: 20px;
								}

								&.bootstrap-touchspin-down {
									left: 0;
									right: auto;

									&::before {
										content: "-";
									}
								}
							}
						}
					}

					@include media-breakpoint-up("sm") {
						width: 140px;
					}
				}

				.price {
					text-align: center;
				}

				.remove-from-cart {
					color: $bleu-uf;

					i {
						font-size: 18px;
					}
				}
			}
		}
	}
}

.cart-grid-body {
	.btn-default-continue {
		margin-top: 1rem;

		@include media-breakpoint-down("sm") {
			margin-bottom: 1rem;
		}
	}
}

.cart-grid-right {
	.card.cart-summary {
		$bleu-cart: #00b2bf;
		background-color: #fff;
		border-top: 4px solid $bleu-cart;
		border-bottom: 1px solid $bleu-cart;
		border-left: 1px solid $bleu-cart;
		border-right: 1px solid $bleu-cart;
		padding: 2rem;

		.title {
			font-family: $ff-secondary;
			font-size: 25px;
			text-transform: uppercase;
			padding-left: 47px;
			position: relative;
			display: table;
			margin-left: 0;
			margin-right: auto;
			margin-bottom: 1rem;

			&::before {
				content: "";
				background: url("../img/picto-ceres.png") no-repeat 50% 50% / contain;
				width: 29px;
				height: 37px;
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		#cart-subtotal-products {
			display: flex;

			.value {
				margin-left: auto;
				color: $bleu-cart;
				font-weight: 700;
			}
		}

		.cart-detailed-totals .card-block {
			background-color: #f4f4f4;

			.cart-summary-line {
				padding: 10px;
			}
		}

		.cart-summary-totals {
			border-top: 1px solid $bleu-cart;
			padding: 12px 10px;
			font-weight: 700;
			color: $bleu-cart;
		}
	}

	.btn {
		margin-top: 2rem;
		margin-bottom: 2rem;
		background-color: #00b2bf;
		border: none;
		text-transform: uppercase;
		display: inline-flex;
		align-items: center;
		padding: 0.8rem 1rem 0.8rem 3.8rem;
		border-radius: 12px;
		color: white;
		font-weight: 700;
		justify-content: space-around;
		position: relative;
		transition: background-color 300ms ease;

		>.icon {
			position: absolute;
			width: 40px;
			height: 40px;
			left: 0.8rem;
			background: url("../img/add-to-cart.svg") no-repeat 50% 50% / 25px;
			display: block;
		}

		>.hover {
			position: absolute;
			display: block;
			width: 40px;
			height: 40px;
			position: absolute;
			left: 0.8rem;
			background-color: #fff;
			border-radius: 50%;
			overflow: hidden;
			transition: background-color 500ms ease;

			&::after {
				content: "";
				background: url("../img/bg-wave-2.svg") repeat-x;
				background-size: 40px;
				transform: scaleY(0);
				transition: transform 300ms ease, left 1200ms ease;
				transform-origin: bottom;
				position: absolute;
				bottom: -5px;
				left: 0;
				width: 160px;
				height: 100%;
			}
		}

		&:hover {
			background-color: $bleu-uf;

			>.hover {

				//background-color: #3ca3bf;
				&::after {
					transform: scaleY(0.8);
					left: -200%;
				}
			}
		}
	}

	.payment-method-logo-block {
		display: flex;
		flex-wrap: wrap;

		&-title {
			flex: 1 0 100%;
			margin-bottom: 1rem;
		}

		.payment-method-logo {
			flex: 1 0 25%;
			padding: 8px;
			box-sizing: border-box;
		}
	}
}

#cart.cart-empty {
	.cart-grid-right {
		display: none;
	}

	.cart-grid-body {
		button {
			@extend %btn-default;
		}

		a.label {
			@extend %btn-default;
			margin-top: 2rem;
		}
	}
}


#cart {
	.block-promo {
		margin-top: 1rem;
		text-align: center;
		.alert.alert-danger.js-error {
			display: none;
		}

		.collapse {
			height: 0;
			overflow: hidden;

			&.in {
				height: 100%;
			}
		}

		.collapse-button {
			color: #424242;
		}
		.collapse-button.promo-code-button.cancel-promo {
			margin-top: 16px;
			display: inline-block;
		}
	}

	button {
		padding: 0.8rem 1rem 0.8rem 1rem;
		margin-top: 0;
		margin-bottom: 0;
	}
}