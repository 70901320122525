// share
//share
#top-menu {
	list-style-type: none;
	margin: 0;
	padding: 0;
	a {
		color: #000;
		font-weight: 700;
		text-transform: uppercase;
		font-size: 12px;
		display: block;
		transition: color 300ms ease;
		font-weight: 900;
		&:hover {
			color: $bleu-m;
		}
	}
	.dropdown-item {
		display: inline-flex;
		align-items: center;
		flex-direction: row-reverse;
		span {
			font-size: 12px;
			line-height: 1;
			i {
				font-size: 16px;
			}
		}
	}
	.sub-menu {
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}
	}
}

main {
	&::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 9999;
		background-color: rgba(#000, 0.85);
		opacity: 0;
		visibility: hidden;
		transition: opacity 300ms ease;
	}
}
// only mobile
@media screen and (max-width: 994px) {
	$mobile-nav-width: 300px;
	.menu-open {
		transform: translateX(-$mobile-nav-width);
		main {
			max-height: 100vh;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			height: 100vh;
			width: 100vw;
			overflow: hidden;
			z-index: 1;
			&::after {
				opacity: 1;
				visibility: visible;
			}
		}
		button.toggle-menu {
			opacity: 0;
		}
		.header-nav {
			button.toggle-menu {
				opacity: 1;
			}
		}
		button.toggle-menu {
			transition: opacity 300ms ease;
			.hamburger {
				background-color: transparent;
			}
			.hamburger::after {
				transform: rotate(45deg) translateY(50%);
				bottom: 50%;
			}
			.hamburger::before {
				transform: rotate(-45deg) translateY(-50%);
				top: 50%;
			}
		}
	}
	.header-nav {
		position: fixed;
		right: -$mobile-nav-width;
		top: 0;
		bottom: 0;
		background-color: #fff;
		z-index: 9999;
		width: $mobile-nav-width;
		padding-top: 50px;
		button.toggle-menu {
			top: 20px;
			transform: translateY(0);
			left: 20px;
			opacity: 1;
		}
		.container {
			padding-left: 0;
			padding-right: 0;
		}
	}
	#_desktop_top_menu > #top-menu > li > a {
		padding: 1rem 30px;
		width: 100%;
		display: block;
		text-align: left;
		position: relative;
		.float-xs-right {
			position: absolute;
			z-index: 999;
			background-color: #eee;
			height: 100%;
			width: 50px;
			top: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	#_desktop_top_menu > #top-menu > li > .sub-menu {
		transition: height 300ms ease;
		&.collapse {
			height: 0;
			overflow: hidden;
			transition: height 300ms ease;
		}
		> ul {
			background-color: $bleu-uf;
			padding: 0.625rem 0 0.625rem 0;
			box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.3);
			> li > .dropdown-item {
				padding: 0.625rem 30px;
				color: #fff;
			}
		}
	}
}
// only desk
@media screen and (min-width: 995px) {
	button.toggle-menu {
		display: none;
	}
	#top-menu {
		display: table;
		padding: 0;
		text-align: center;
		margin: 0 auto;
		li {
			display: table-cell;
			vertical-align: middle;
			&::before {
				display: none;
			}
			a {
				padding: 8px 8px;
			}
		}

		.sub-menu {
			position: absolute;
			z-index: 9999;
			padding-top: 4px;
			opacity: 0;
			visibility: hidden;
			transition: opacity 300ms, transform 500ms ease;
			ul {
				display: block;
				background-color: rgba(#fff, 0.9);
				box-shadow: 8px 8px 8px rgba($color: #fff, $alpha: 0.3);
				padding-top: 10px;
				padding-bottom: 10px;
				padding-left: 0;
				transform: translateY(50px);
				transition: transform 500ms ease;
				width: 235px;
				li {
					display: block;
					text-align: left;
					position: relative;
					&::before {
						display: none;
					}
					a {
						padding: 12px 16px 12px 16px;
						display: block;
					}
					> .dropdown-submenu {
						.float-xs-right {
							float: right;
							transform: rotate(-90deg);
						}
					}
					> .collapse {
						position: absolute;
						transform: translateX(100%);
						top: 0;
						display: none;
						opacity: 0;
						transition: opacity 300ms ease;
					}
					&:hover {
						> .collapse {
							opacity: 1;
							display: block;
						}
					}
				}
			}
		}

		// hover to display submenu
		> li:hover {
			.sub-menu {
				opacity: 1;
				visibility: visible;
				ul {
					transform: translateY(0);
				}
			}
		}
	}
	
	
}

#_desktop_cart .account:hover, #_desktop_cart .wrapper-link:hover, #_desktop_user_info .account:hover, #_desktop_user_info .wrapper-link:hover {
	@include media-breakpoint-down("sm") {
		color: #217182 !important;
	}
}

button.toggle-menu {
	//hamburger
	width: 30px;
	height: 20px;
	position: absolute;
	border: none;
	background: #fff;
	top: 50%;
	right: 15px;
	transform: translateY(-50%);
	@include media-breakpoint-down("sm") {
		bottom: 16px;
		top: auto;
		transform: none;
	}
	.hamburger {
		$border-width: 2px;
		display: block;
		position: absolute;
		height: $border-width;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		background-color: $bleu-n;
		&::after,
		&::before {
			content: "";
			position: absolute;
			height: $border-width;
			width: 100%;
			background-color: $bleu-n;
			left: 0;
		}
		&::before {
			top: -10px;
		}
		&::after {
			bottom: -10px;
		}
	}
}

#_desktop_cart,
#_desktop_user_info {
	display: inline-block;
	text-align: center;
	.user-info {
		position: relative;
	}
	.account,
	.wrapper-link {
		display: inline-flex;
		align-items: center;
		overflow: hidden;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 700;
		color: $bleu-c;
		padding: 6px 12px;
		position: relative;
		z-index: 1;
		border-radius: 4px;
		background-color: #fff;
		&::after {
			content: "";
			background-color: $bleu-uf;
			position: absolute;
			left: 0;
			top: 0;
			width: 0%;
			height: 100%;
			display: block;
			z-index: -1;
			transition: width 300ms ease;
			@include media-breakpoint-down("md") {
				display: none;
			}
		}
		i {
			display: block;
			width: 40px;
			height: 40px;
			text-indent: -999px;
			border-radius: 50%;
			@include media-breakpoint-up("lg") {
				margin-right: 8px;
			}
		}

		&:hover {
			color: white;
			&::after {
				width: 100%;
			}
		}
	}

	.logout {
		position: absolute;
		display: block;
		width: 40px;
		height: 40px;
		top: 6px;
		left: 12px;
		border-radius: 50%;
		z-index: 998;
		transition: opacity 300ms ease;
		&:hover {
			opacity: 1;
			background: $orange url("../img/logout.svg") no-repeat 50% 50% /
				25px;
		}
		@include media-breakpoint-down("md") {
			display: none;
		}
	}

	.logout-text {
		display: block;
		position: absolute;
		right: 0;
		padding: 6px 12px;
		bottom: 0;
		transition: transform 300ms ease-in-out;
		transform: translateY(0%);
		background-color: $orange;
		color: white;
		border-radius: 0 0 5px 5px;
	}

	&:hover {
		.logout-text {
			transform: translateY(95%);
			box-shadow: 0 4px 4px #00000040;
		}
	}
	.hidden-sm-down {
		@include media-breakpoint-down("sm") {
			display: inline-block;
			max-width: 100px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		@include media-breakpoint-up("lg") {
			display: inline-block;
			max-width: 120px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}
}
#_desktop_user_info .account {
	i {
		background: #fff url("../img/account.svg") no-repeat 50% 50% / 25px;
	}
}
#_desktop_cart .wrapper-link {
	i {
		background: #fff url("../img/cart.svg") no-repeat 50% 50% / 25px;
		margin-right: 20px;
	}
	position: relative;
	.cart-products-count {
		position: absolute;
		width: 20px;
		height: 20px;
		border: 1px solid $bleu-c;
		border-radius: 50%;
		line-height: 0;
		display: block;
		font-size: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		left: 45px;
		top: 50%;
		transform: translateY(-50%);
		background-color: #fff;
		color: $bleu-c;
	}
}
