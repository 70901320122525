%green-gradient {
	background: linear-gradient(#00aa57 0%, #097240 50%, #00aa57 100%);
}
%reset-ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

%transi-trans {
    transition: transform 300ms ease;
}

@mixin initBefore() {
    position: relative;
    &::before {
        content:'';
        display: block;
    }
}
@mixin initAfter() {
    position: relative;
    &::after {
        content: '';
        display: block;
    }
}
@mixin initBeforeAfter() {
    position: relative;
    &::after,
    &::before {
        content: '';
        position: absolute;
        display: block;
    }
}

.mb-10 {
    margin-bottom: 5rem !important;
}

.center_v {
    display: flex;
    align-items: center;
    height: 100%;
}

.equal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
}
.equal > [class*='col-'] {
    display: flex;
    flex-direction: column;
}

.font-1 {
    font-family: $ff-primary;
}
.font-2 {
    font-family: $ff-secondary;
}
.font-3 {
    font-family: $ff-tertiary;
}

.row-full {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
}

@mixin make-row-full() {
    @extend .row-full;
}