#password {
  .col-md-8.email input {
    width: 100%;
  }

  #content .ps-alert-success {
    background-color: rgb(105, 179, 91);
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;
    li {
      display: flex;
      align-items: center;
      color: white;
      padding: 20px;

      i {
        display: inline-block;
        width: 18px;
        margin-right: 10px;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}