#reassurance-sidebar-category {
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		li {
			display: flex;
			align-items: center;
			margin-bottom: 2rem;
			text-transform: uppercase;
			font-family: "Oswald";
			color: $bleu-m;
			img {
				margin-right: 12px;
			}
		}
	}
}

#category #home_content {
	padding-top: 3rem;
	padding-bottom: 3rem;
	h2 {
		font-family: $ff-secondary;
		font-size: 30px;
		text-transform: uppercase;
		padding-left: 37px;
		position: relative;
		display: table;
		margin-left: auto;
		margin-right: auto;
		@include media-breakpoint-down("md") {
			font-size: 22px;
			line-height: 24px;
		}
		
		&::before {
			content: "";
			background: url("../img/picto-ceres.png") no-repeat 50% 50% / contain;
			width: 29px;
			height: 37px;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	p {
		font-size: 18px;
	}
}
#category .wrapper-columns {
	@include make-row-full();
	background-color: #f4f4f4;
	padding-top: 3rem;
    @include media-breakpoint-down('sm') {
        .row {
            flex-direction: column-reverse;
        }
    }

	#left-column,
	#content-wrapper {
		@include make-col-ready();
	}
	@include media-breakpoint-up("md") {
		#left-column {
			@include make-col(4);
		}
		#content-wrapper {
			@include make-col(8);
		}
	}
	@include media-breakpoint-up("lg") {
		#left-column {
			@include make-col(3);
		}
		#content-wrapper {
			@include make-col(9);
		}
	}
}
#left-column {
	#search_filters {
		background-color: #fff;
		padding: 2rem 1rem;
		margin-bottom: 2rem;
		> .h6 {
			text-transform: uppercase;
			font-family: "Oswald";
			font-size: 20px;
			color: $bleu-uf;
			border-bottom: 2px solid $bleu-uf;
			padding-bottom: 6px;
			margin-bottom: 1rem;
		}
		.custom-checkbox {
			position: relative;
			margin-right: 5px;
			border: 2px solid #eee;
			height: 20px;
			width: 20px;
			> span {
				i {
					opacity: 0;
					position: absolute;
					top: 0;
					left: 0;
					font-size: 16px;
					color: $bleu-m;
				}
			}
			font-size: 12px;
			input[type="checkbox"] {
				display: none;
				&:checked ~ span i {
					opacity: 1;
				}
			}
		}
		#_desktop_search_filters_clear_all {
			button {
				background: none;
				border: none;
				padding: none;
				line-height: 1;
				color: #fca10e;
				padding: 0 0 1rem 0;
				i {
					font-size: 14px;
					line-height: 1;
				}
			}
		}
		section.facet {
			p.h6.facet-title {
				text-transform: uppercase;
				font-family: "Oswald";
				color: $bleu-m;
				margin-top: 1rem;
			}
			ul {
				list-style-type: none;
				margin: 0;
				padding: 0;
				margin-bottom: 2rem;
				li {
					display: block;
					label.facet-label {
						display: flex;
						align-items: center;
                        a.search-link {
                            transform: translateX(-25px);
                            padding-left: 25px;
                            display: inline-block;
                        }
						.custom-checkbox {
							top: 0 !important;
						}
						> a {
							font-family: $ff-secondary;
							color: $bleu-uf;
						}
					}
				}
			}
		}
	}

	.new-sidebar-cat {
		position: relative;
		padding: 4rem 1.5rem 180px 1.5rem;
		background: #99c8ce url("../img/a-composer.png") no-repeat center bottom /
			contain;
		.ribbon {
			background-color: #bc0049;
			color: white;
			text-transform: uppercase;
			font-size: 14px;
			padding: 4px 20px;
			border-radius: 45px;
			position: absolute;
			display: inline-block;
			right: 16px;
			top: 16px;
			font-family: $ff-secondary;
		}
		.titre {
			font-family: $ff-secondary;
			text-transform: uppercase;
			color: white;
			font-size: 24px;
		}
		a {
			text-transform: uppercase;
			color: white;
			display: block;
			margin-top: 1rem;
		}
	}
}
#js-product-list-header {
	@include make-row-full;
	background-size: cover;
	background-repeat: no-repeat;
    background-position: 50% 50%;
	position: relative;
    height: 200px;
    @include media-breakpoint-up('sm') {
        height: 300px;
    }
    @include media-breakpoint-up('lg') {
        min-height: 500px;
    }

	.category-header {
		@extend .container;
		text-align: center;
		.h1 {
			padding-top: 1rem;
			padding-bottom: 1rem;
			margin-bottom: 0;
			color: white;
			text-transform: uppercase;
			font-family: $ff-secondary;
			font-size: 2rem;
			mix-blend-mode: normal;
			z-index: 2;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
	.bg-title {
		mix-blend-mode: multiply;
		background-color: rgba(#294e6b, 0.6);
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
		height: 80px;
	}
}

#js-product-list-top,
#js-product-list .pagination {
	background-color: #99c8ce;
	margin-left: 0;
	margin-right: 0;
	color: white;
	font-family: $ff-secondary;
	font-size: 14px;
	font-weight: 700;
	align-items: center;
	padding: 1rem;
}
#js-product-list-top {
	background-color: #99c8ce;
	margin-left: 0;
	margin-right: 0;
	color: white;
	font-family: $ff-secondary;
	font-size: 14px;
	font-weight: 700;
	align-items: center;
	padding: 1rem;
    &:empty {
        display: none;
    }
    @include media-breakpoint-between(sm, md) {
        padding: 12px 0;
        .col-md-6:first-child {
            @include make-col(4);
        }
        .col-md-6:last-child {
            @include make-col(8);
        }
    }
	.sort-by-row {
		align-items: center;
	}
	p {
		margin-bottom: 0;
	}
	.products-sort-order.dropdown {
		position: relative;
		button.select-title {
			background-color: #fff;
			width: 100%;
			border-radius: 45px;
			border: 1px solid $bleu-f;
			text-align: left;
			padding: 8px 20px;
			font-weight: 700;
			color: #99c8ce;
			position: relative;
			span {
				font-size: 20px;
				line-height: 1;
				position: absolute;
				right: 20px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		.dropdown-menu {
			position: absolute;
			background-color: #fff;
			width: calc(100% - 30px);
			padding-top: 16px;
			padding-bottom: 24px;
			transform: translateY(4px);
			border-radius: 4px;
			border: 1px solid $bleu-f;
			z-index: 999;
			opacity: 0;
			visibility: hidden;
			a {
				display: block;
				padding: 8px 0 0 20px;
				box-sizing: border-box;
				font-weight: 700;
				color: #99c8ce;
			}
		}
		&.open {
			.dropdown-menu {
				visibility: visible;
				opacity: 1;
			}
		}
	}
	.filter-button {
		display: none;
	}
}
#products {
	.subcategories {
		background-color: #3ca4c0;
		padding: 2rem 1rem;
		box-shadow: 8px 8px 8px #00000060;
		margin-bottom: 2rem;
		&-title {
			text-transform: uppercase;
			font-family: $ff-secondary;
			color: white;
			font-size: 18px;
			padding-left: 8px;
		}
		&-list {
			display: flex;
			flex-wrap: wrap;
			list-style-type: none;
			margin: 0;
			padding: 0;
			&__item {
                @include media-breakpoint-up('md') {
                    flex: 0 1 33%;
                }
                @include media-breakpoint-between(sm, md) {
                    flex: 0 1 50%;
                }
				&--link {
					display: table;
					img {
						display: table-cell;
						vertical-align: middle;
						padding-left: 0;
						text-align: left;
						max-width: 69px;
					}
					&-title {
						color: white;
						font-family: $ff-secondary;
						display: table-cell;
						vertical-align: middle;
						width: auto;
						transition: color 300ms ease;
					}
				}
			}
			.subcategories-list__item--link:hover {
				.subcategories-list__item--link-title {
					color: $bleu-f !important;
				}
			}
		}
	}
	#js-active-search-filters {
		display: table;
		padding: 1rem 0;
		.h6 {
			color: $bleu-uf;
			font-family: $ff-secondary;
			display: table-cell;
			font-size: 24px;
		}
		ul {
			display: table-cell;
			list-style: none;
			margin: 0;
			padding: 0;
			padding-left: 16px;
			li.filter-block {
				display: inline-block;
				font-size: 15px;
				line-height: 1;
				background-color: $orange;
				color: white;
				border-radius: 3px;
				margin-right: 8px;
				padding: 1rem;
				.js-search-link {
					color: white;
					line-height: 1;
					margin: 0;
					padding: 0;
					font-size: 15px;
					i {
						font-size: 15px;
						line-height: 0.9;
						color: white;
					}
				}
			}
		}
	}
}

#js-product-list {
	> .products.row {
		> div {
			@include make-col-ready();
			padding-bottom: 30px;
			@include media-breakpoint-up("md") {
				@include make-col(6);
			}
			@include media-breakpoint-up("lg") {
				@include make-col(4);
			}
		}
	}
	.pagination {
		@include make-row();
		align-items: center;
		margin-bottom: 4rem;
        @include media-breakpoint-down('sm') {
            .col-md-4 {
                display: none;
            }
            .col-md-6 {
                @include make-col(10);
            }
        }
		p {
			margin-bottom: 0;
		}
		.page-list {
			margin: 0;
			padding: 0;
			text-align: right;
			display: table;
			margin-right: 0;
			margin-left: auto;
			li {
				display: table-cell;
				vertical-align: middle;
				&:not(:last-child) {
					padding-right: 8px;
				}
				&::before {
					display: none;
				}
				a,
				i {
					transition: background-color 300ms ease, color 300ms ease;
				}
				a {
					font-size: 17px;
					line-height: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					color: white;
					width: 40px;
					height: 40px;
					border-radius: 50%;
					&:hover {
						background-color: #82b8bc;
					}
					i {
						font-size: 24px;
						line-height: 1;
						font-weight: 900;
					}
					&.next,
					&.previous {
						background-color: #82b8bc;
						i {
							color: #217683;
						}

						&:hover {
							background-color: #217683;
							i {
								color: white;
							}
						}
					}
				}
			}
			li.current a {
				background-color: #217683;
			}
		}
	}
}

#category-description-seo {
	padding-bottom: 4rem;
	h2 {
		font-family: $ff-secondary;
		font-size: 30px;
		text-transform: uppercase;
		padding-left: 45px;
		position: relative;
		display: table;
		margin-left: 0;
		margin-top: 2rem;
		margin-bottom: 1rem;
		@include media-breakpoint-down("md") {
			font-size: 22px;
			line-height: 24px;
		}
		&::before {
			content: "";
			background: url("../img/picto-ceres.png") no-repeat 50% 50% / contain;
			width: 29px;
			height: 37px;
			position: absolute;
			top: 0;
			left: 0;
			@include media-breakpoint-down("md") {
				top: -8px;
			}
		}
	}
	strong {
		color: $bleu-m;
		text-transform: uppercase;
		font-family: $ff-secondary;
		font-weight: 400;
	}
}

#search {
	#main {
		padding-top: 6rem;
	}
}