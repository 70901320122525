a {
	transition: color 300ms ease;
	text-decoration: none;
	&:hover {
		text-decoration: none;
		color: #000;
	}
}
body,
html {
	position: relative;
	//overflow-x: hidden;
	font-size: 16px;
	font-family: $ff-primary;
	font-weight: 400;
	transition: transform 300ms ease;
}

img {
	max-width: 100%;
	height: auto;
}
h1,
h2,
h3,
h4 {
	font-weight: normal;
}

.hide {
	display: none;
}


.hidden-sm-down {
    @include media-breakpoint-down('sm') {
        display: none;
    }
}
.hidden-md-up {
    @include media-breakpoint-up('md') {
        display: none;
    }
}
.hidden-sm-up {
    @include media-breakpoint-up('sm') {
        display: none;
    }
}