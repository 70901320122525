.footer-container {
	color: #fff;
	background-color: #101417;
	padding: 5px 0;
	div.social--network {
		display: inline-block;
	}
	p {
		padding: 0;
		margin: 0;
	}
	a {
		color: #fff;
		text-transform: uppercase;
		&:hover {
			color: #fff;
		}
		&:active {
			color: #fff;
		}
	}
}

.footer--line-spacing {
	border-top: 1px solid #fff;
	width: 100%;
	height: 1px;
}

.footer--links {
	font-size: 0.9rem;
}

.footer--address {
	font-size: 0.9rem;
}
.copyright {
	margin-top: 2rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
	border-top: 2px solid white;
}
.footer--copyright {
	font-size: 0.7rem;
}

.footer--text-size-smaller {
	font-size: 0.7rem;
}

.footer--spacing {
	margin-top: 2.5rem !important;
	margin-bottom: 5rem !important;
}

.footer--contact {
	@include media-breakpoint-down("sm") {
		padding: 1rem;
	}
	display: inline-flex;
	&::before {
		content: "";
		width: 41px;
		height: 44px;
		background-size: contain;
		background-repeat: no-repeat;
		background-image: url(../img/footer-contact.png);
		margin-right: 12px;
	}
}

footer div > img {
	@include media-breakpoint-down("sm") {
		padding: 1rem;
	}
}

a.picto_facebook {
	height: 25px;
	width: 25px;
	margin: 6px;
	background-image: url(../img/picto-facebook.png);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	display: inline-block;
}
a.picto_instagram {
	height: 25px;
	width: 25px;
	margin: 6px;
	background-image: url(../img/picto-instagram.png);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	display: inline-block;
}
a.picto_twitter {
	height: 25px;
	width: 25px;
	margin: 6px;
	background-image: url(../img/picto-twitter.png);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	display: inline-block;
}

#back-to-top {
	width: 44px;
	height: 53px;
	background: url("../img/icon-btt.svg") no-repeat 50% 50% / contain;
	position: fixed;
	bottom: 30px;
	right: 30px;
	z-index: 99999;
	text-align: center;
	border: none !important;
	&::after,
	&::before {
		content: "";
	}
	&::before {
		content: "";
		border: solid $bleu-f;
		border-width: 0 2px 2px 0;
		display: inline-block;
		padding: 3px;
		transform: rotate(-135deg);
		position: relative;
		top: -34px;
		transition: top 300ms ease;
	}

	&:hover:before {
		top: -38px;
	}
}

#fix-left-btn {
	position: fixed;
	left: 20px;
	top: 20%;
	z-index: 9998;
	@include media-breakpoint-down("md") {
		display: none;
	}
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		li {
			margin: 1rem 0;
			a {
				display: block;
				width: 60px;
				height: 60px;
				border-radius: 25px;
				background-size: 26px;
				background-repeat: no-repeat;
				background-position: 50% 50%;
				transition: background-color 300ms, box-shadow 300ms ease,
					transform 300ms ease;
				transform-origin: left;
				transform: translate(0px, 0px);
				&:hover {
					transform: translate(3px, -3px);
					box-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
				}
			}
		}
		li:first-child {
			a {
				background-color: #9ac9cf;
				background-image: url("../img/fix-left-search.svg");
				&:hover {
					background-color: darken(#9ac9cf, 10%);
				}
			}
		}
		li:nth-child(2) {
			a {
				background-color: #3ca4c0;
				background-image: url("../img/fix-left-contact.svg");
				&:hover {
					background-color: darken(#3ca4c0, 5%);
				}
			}
		}
		li:nth-child(3) {
			a {
				background-color: #217182;
				background-image: url("../img/fix-left-boat.svg");
				&:hover {
					background-color: darken(#217182, 5%);
				}
			}
		}
		li:last-child {
			a {
				background-color: #9ac9cf;
				background-image: url("../img/fix-left-gift.svg");
				&:hover {
					background-color: darken(#9ac9cf, 10%);
				}
			}
		}
	}
}
