%h1-type-account {
	font-size: 24px;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
}
#authentication {
	.login-col-container {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 4rem;
		h2 {
			font-family: $ff-secondary;
			font-size: 25px;
			text-transform: uppercase;
			padding-left: 47px;
			position: relative;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 2rem;
			&::before {
				content: "";
				background: url("../img/picto-ceres.png") no-repeat 50% 50% /
					contain;
				width: 29px;
				height: 37px;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
		.col-md-8 {
			margin-bottom: 2rem;
		}
		.col-md-8,
		.col-md-4 {
			text-align: center;
			.login-form {
				text-align: left;
			}
		}
		.forgot-password {
			text-align: center;
			padding-top: 1rem;
			padding-bottom: 1rem;
			a {
				color: $bleu-c;
				&:hover {
					color: $bleu-f;
				}
			}
		}
		#submit-login,
		[data-link-action="display-register-form"] {
			@extend %btn-default;
		}
		input {
			width: 100%;
		}

		@include media-breakpoint-up("lg") {
			width: 80%;
			.col-md-8 {
				margin-bottom: 0;
			}
		}
	}

	.register-form {
		> p {
			font-size: 25px;
			text-align: center;
			a {
				@extend %btn-default;
				font-size: 15px;
				display: table;
				margin-left: auto;
				margin-right: auto;
				margin-top: 0.625rem;
			}
		}

		#customer-form {
			text-align: center;
			padding-bottom: 4rem;
			> section {
				@include make-row();
				.form-group {
					@include make-col-ready();
					@include media-breakpoint-up("md") {
						@include make-col(6);
					}
					margin-bottom: 1rem;
					text-align: left;
					input[type="text"],
					input[type="email"] {
						width: 100%;
					}
					&.checkboxes {
						@include make-col(12);
						margin-bottom: 0;
					}
				}
			}
			button.form-control-submit {
				@extend %btn-default;
				margin-top: 1rem;
			}

			@include media-breakpoint-up("sm") {
				max-width: 80%;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}

#my-account {
	.links {
		@include make-row();
		> a {
			display: block;
			@include make-col-ready();
			@include make-col(4);
			padding-bottom: 30px;
			span {
				display: flex;
				align-items: center;
				height: 100%;
				padding: 2rem 180px 2rem 1.5rem;
				color: white;
				text-transform: uppercase;
				font-family: $ff-secondary;
				font-size: 20px;
				line-height: 22px;
				position: relative;
				&::before {
					content: "";
					border: 4px solid transparent;
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					display: block;
					transition: border-right-color 100ms linear,
						border-bottom-color 100ms 50ms linear,
						border-left-color 100ms 100ms linear,
						border-top-color 100ms 150ms linear;
				}
				&:hover::before {
					border-right-color: $bleu-uf;
					border-bottom-color: $bleu-uf;
					border-left-color: $bleu-uf;
					border-top-color: $bleu-uf;
				}
			}
			@include media-breakpoint-down(md) {
				@include make-col(6);
			}
			@include media-breakpoint-down(sm) {
				@include make-col(12);
			}
		}
		#identity-link {
			span {
				background: #9ac9cf
					url("../img/picto-account/identity-link.svg") no-repeat top
					50% right 1.5rem / auto 64px;
				@include media-breakpoint-down(md) {
					background: #9ac9cf
						url("../img/picto-account/identity-link.svg") no-repeat
						top 15% right 50% / auto 44px;
					padding: 6rem 2rem 2rem 2rem;
					text-align: center;
					justify-content: center;
				}
			}
		}
		#addresses-link {
			span {
				background: #00b2bf
					url("../img/picto-account/addresses-link.svg") no-repeat top
					50% right 1.5rem / auto 64px;
				@include media-breakpoint-down(md) {
					background: #00b2bf
						url("../img/picto-account/addresses-link.svg") no-repeat
						top 15% right 50% / auto 44px;
					padding: 6rem 2rem 2rem 2rem;
					text-align: center;
					justify-content: center;
				}
			}
		}
		#cadeauxPrives-link {
			span {
				background: #00b2bf url("../img/fix-left-gift.svg") no-repeat
					top 50% right 1.5rem / auto 60px;
				@include media-breakpoint-down(md) {
					background: #00b2bf
						url("../img/fix-left-gift.svg") no-repeat
						top 15% right 50% / auto 44px;
					padding: 6rem 2rem 2rem 2rem;
					text-align: center;
					justify-content: center;
				}
			}
		}
		#history-link {
			span {
				background: #217182 url("../img/picto-account/history-link.svg")
					no-repeat top 50% right 1.5rem / auto 64px;
				@include media-breakpoint-down(md) {
					background: #217182
						url("../img/picto-account/history-link.svg") no-repeat
						top 15% right 50% / auto 44px;
					padding: 6rem 2rem 2rem 2rem;
					text-align: center;
					justify-content: center;
				}
			}
		}
		#order-slips-link,
		#discounts-link {
			span {
				background: #217182
					url("../img/picto-account/order-slips-link.svg") no-repeat
					top 50% right 1.5rem / auto 64px;
				@include media-breakpoint-down(md) {
					background: #217182
						url("../img/picto-account/order-slips-link.svg")
						no-repeat top 15% right 50% / auto 44px;
					padding: 6rem 2rem 2rem 2rem;
					text-align: center;
					justify-content: center;
				}
			}
		}
		#psgdpr-link {
			span {
				background: #9ac9cf
					url("../img/picto-account/identity-link.svg") no-repeat top
					50% right 1.5rem / auto 64px;
				@include media-breakpoint-down(md) {
					background: #9ac9cf
						url("../img/picto-account/identity-link.svg") no-repeat
						top 15% right 50% / auto 44px;
					padding: 6rem 2rem 2rem 2rem;
					text-align: center;
					justify-content: center;
				}
			}
			i {
				display: none;
			}
		}
	}
}

#identity {
	.page-header {
		h1 {
			@extend %h1-type-account;
		}
	}
	section#content {
		#customer-form {
			> section {
				@include make-row();
				.form-group {
					@include make-col-ready();
					@include media-breakpoint-up("md") {
						@include make-col(6);
					}
					margin-bottom: 1rem;
					input[type="text"],
					input[type="email"] {
						width: 100%;
					}
					&:first-child,
					&.checkboxes {
						@include make-col(12);
					}
					&.checkboxes {
						margin-bottom: 0;
					}
				}
			}
			> footer {
				text-align: center;
				margin-bottom: 2rem;
				button {
					@extend %btn-default;
					margin-top: 2rem;
				}
			}
		}
	}
}

#addresses {
	.page-header h1 {
		@extend %h1-type-account;
	}
	#content.page-content {
		@include make-row();
		aside,
		.addresses-footer {
			@include make-col-ready();
			@include make-col(12);
			text-align: center;
		}
		aside {
			margin-bottom: 1rem;
		}
		.address-body {
			h4 {
				font-weight: 700;
				color: $bleu-uf;
				font-size: 18px;
				border-bottom: 2px solid $bleu-uf;
			}
		}
		.addresses-footer {
			margin: 2rem auto;
		}
		a {
			color: $bleu-cart;
			&:hover {
				color: $bleu-uf;
			}
			i {
				font-size: 13px;
			}
		}
	}
}

#address {
	.page-header h1 {
		@extend %h1-type-account;
	}

	#content {
		.address-form .js-address-form {
			form {
				.form-fields {
					input {
						width: 100%;
					}
					@include media-breakpoint-up("sm") {
						@include make-row();
						.form-group {
							@include make-col-ready();
							@include make-col(6);
							margin-bottom: 0.625rem;
						}
					}
				}
				footer.form-footer {
					text-align: center;
					margin: 2rem auto;
					button {
						@extend %btn-default;
					}
				}
			}
		}
	}
}

#history {
	.page-header h1 {
		@extend %h1-type-account;
	}
	#content.page-content {
		margin-bottom: 2rem;
		h6 {
			font-size: 18px;
			text-align: center;
			color: $bleu-uf;
			margin-bottom: 1rem;
		}
		span.label.label-pill.bright {
			background-color: $bleu-uf !important;
			color: white;
			padding: 4px;
		}
		table {
			border: 1px solid #424242;
			.thead-default {
				tr {
					background-color: $bleu-uf;
					color: white;
					border-bottom: 1px dotted #424242;
					th {
						padding: 10px;
						font-weight: 700;
						width: auto;
						line-height: 1;
						&:first-child {
							min-width: 200px;
						}
						&:nth-child(3) {
							min-width: 120px;
						}
					}
				}
			}
			tbody {
				tr:nth-child(even) {
					background-color: rgba($bleu-c, 0.3);
				}
				td,
				th {
					padding: 8px 10px;
				}
				td.order-actions {
					a {
						color: $bleu-m;
						display: block;
						line-height: 1;
						&:first-child {
							margin-bottom: 0.625rem;
						}
					}
				}
			}
		}
		.orders {
			@extend .container;
			.order {
				margin-bottom: 2rem;
				a {
					color: $bleu-c;
				}
			}
		}
	}
}

#order-detail {
	.page-header h1 {
		@extend %h1-type-account;
	}
	span.label.label-pill.bright {
		background-color: $bleu-uf !important;
		color: white;
		padding: 4px;
	}
	a {
		color: $bleu-cart;
		&:hover {
			color: $bleu-uf;
		}
		i {
			font-size: 13px;
		}
	}
	.box {
		box-shadow: 0 0 4px #ccc;
		padding: 1rem;
		margin: 1rem auto;
		border-radius: 4px;
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}
	}
	#order-history {
		h3 {
			border-bottom: 1px solid #ccc;
			padding-bottom: 0.5rem;
			margin-bottom: 0.5rem;
		}
		table {
			border: 1px solid #424242;
			width: 100%;
			.thead-default {
				tr {
					background-color: $bleu-uf;
					color: white;
					border-bottom: 1px dotted #424242;
					th {
						padding: 10px;
						font-weight: 700;
						width: auto;
						line-height: 1;
					}
				}
			}
			tbody {
				tr:nth-child(even) {
					background-color: rgba($bleu-c, 0.3);
				}
				td,
				th {
					padding: 10px;
				}
				td:first-child {
					color: $bleu-uf;
					font-weight: 700;
				}
			}
		}
	}
	.addresses {
		@include make-row();
		h4 {
			font-weight: 700;
			color: $bleu-uf;
			font-size: 18px;
		}
	}
	table {
		border: 1px solid #424242;
		width: 100%;
		.thead-default {
			tr {
				background-color: $bleu-uf;
				color: white;
				border-bottom: 1px dotted #424242;
				th {
					padding: 10px;
					font-weight: 700;
					width: auto;
					line-height: 1;
				}
			}
		}
		tbody,
		tfoot {
			tr:nth-child(even) {
				background-color: rgba($bleu-c, 0.3);
			}
			td,
			th {
				padding: 10px;
			}
			td:first-child {
				color: $bleu-uf;
				font-weight: 700;
			}
		}
	}
	.order-message-form form {
		textarea {
			width: 100%;
		}
		button {
			@extend %btn-default;
			margin-top: 2rem;
		}
	}
}

#order-slip {
	.page-header h1 {
		@extend %h1-type-account;
	}
}
footer.page-footer {
	text-align: center;
	padding-top: 3rem;
	padding-bottom: 3rem;
	a {
		color: $bleu-f;
		padding: 1rem;
		i {
			font-size: 14px;
		}
	}
}

.center-email-fields {
	padding: 20px 0px;
	.btn-primary {
		margin-top: 20px;
	}
}

.page-customer-account {
	.page-header {
		margin-top: 60px;
	}
}
