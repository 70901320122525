.modal.quickview {
	opacity: 0;
	transition: opacity 300ms ease;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	z-index: 9999;
	&.fade {
		opacity: 1;
	}

	.product-flags {
		list-style-type: none;
		margin: 0;
		padding: 0;
		position: absolute;
		top: 2rem;
		left: 2rem;
		li {
			&.new {
				background-color: #e5bb07;
				color: white;
				padding: 4px 18px;
				font-family: $ff-secondary;
				text-transform: uppercase;
				border-radius: 30px;
				font-size: 12px;
			}
		}
	}

	.modal-header {
		padding: 0.5rem 0.625rem;
		text-align: right;
		position: relative;
		z-index: 1;
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 80px;
			background: url("../img/bg-wave-2.svg") repeat-x bottom 0 left 0 / 40px;
			transform: rotate(180deg);
			z-index: -1;
		}
		.close {
			background-color: #fff;
			border: none;
			color: #424242;
			font-size: 20px;
            position: absolute;
            top: 0;
            right: 0;
            line-height: 40px;
		}
	}

	.modal-dialog {
		position: absolute;
		width: 90%;
		max-width: 1020px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: #fff;
		border: 1px solid #ccc;
		border-radius: 4px;
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 80px;
			background: url("../img/bg-wave-2.svg") repeat-x bottom 0 left 0 / 40px;
		}
        .modal-body {
            padding: 0 2rem 2.5rem 2rem;
        }
		.h1 {
			font-weight: 800;
			color: $bleu-uf;
			font-size: 30px;
			margin-top: 5rem;
			@include media-breakpoint-down("md") {
				margin-top: 0;
                font-size: 20px;
			}
		}
		.product-prices {
			display: table;
			font-size: 20px;
			color: $bleu-m;
			> div {
				display: table-cell;
				&:first-child {
					padding-right: 8px;
				}
			}
		}
		.btn.add-to-cart {
			margin-top: 2rem;
		}
		.product-cover {
			position: relative;
			@include media-breakpoint-down("md") {
				text-align: center;
				width: 300px !important;
				height: 200px;
                margin-left: auto;
                margin-right: auto;
				img {
                    max-height: 200px;
                    width: auto !important;
				}
			}
		}
		.js-qv-mask.mask {
			ul {
				list-style-type: none;
				margin: 0;
				padding: 0;
			}
		}
		.js-qv-product-images {
			display: none;
		}
	}
}
.modal {
	z-index: 9999;
}
.modal.fade {
	opacity: 0;
	visibility: hidden;
	height: 0;
	overflow: hidden;
	&.in {
		opacity: 1;
		height: 100%;
		visibility: visible;
	}
}

#blockcart-modal {
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	bottom: 0;
	background-color: rgba(#000, 0.9);
	opacity: 0;
	transition: opacity 300ms ease;
	.modal-dialog {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		background-color: #fff;
		max-width: 500px;
		width: 90%;
		transform: translateX(100%);
		transition: transform 600ms ease;
	}
	.modal-header {
		padding: 0.5rem 0.625rem;
		text-align: right;
		position: relative;
		z-index: 1;
		padding-top: 1rem;
		#myModalLabel {
			font-size: 18px;
			line-height: 18px;
			margin-bottom: 0;
			color: $bleu-uf;
			text-align: center;
			i {
				font-size: 18px;
				//line-height: 21px;
				transform: translateY(2px);
				display: inline-block;
				margin-right: 8px;
			}
		}
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 65px;
			background: url("../img/bg-wave-2.svg") repeat-x top 0 left 0 / 40px;
			transform: rotate(180deg);
			z-index: -1;
		}
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 40px;
			background-color: #9aebed;
			z-index: -1;
		}
		.close {
			display: none;
		}
	}

	.modal-body {
		padding-top: 4rem;
		padding-left: 2rem;
		padding-right: 2rem;
		.h6 {
			font-weight: 800;
			color: $bleu-uf;
			font-size: 30px;
		}
		.product-price {
			display: table;
			font-size: 20px;
			color: $bleu-m;
			> div {
				display: table-cell;
				&:first-child {
					padding-right: 8px;
				}
			}
		}
		.order-resume {
			.cart-content {
				background-color: $bleu-uf;
				color: white;
				border-radius: 8px;
				overflow: hidden;
				text-align: center;
				.cart-products-count {
					border-bottom: 1px solid white;
					font-family: $ff-secondary;
					padding-bottom: 8px;
					padding: 1rem;
					background-color: #9aebed;
					color: $bleu-uf;
					text-align: center;
					font-size: 18px;
				}
				.product-total {
					background-color: rgba(#fff, 0.1);
					padding: 1rem;
					margin-bottom: 0;
					font-weight: 700;
				}
			}
			.cart-content-btn {
				margin-top: 1rem;
			}
			.btn.btn-primary {
				background-color: #00b2bf;
				border: none;
				text-transform: uppercase;
				display: inline-flex;
				align-items: center;
				padding: 0.8rem 1rem 0.8rem 3.8rem;
				border-radius: 12px;
				color: white;
				font-weight: 700;
				justify-content: space-around;
				position: relative;
				transition: background-color 300ms ease;
				> .icon {
					position: absolute;
					width: 40px;
					height: 40px;
					left: 0.8rem;
					background: url("../img/add-to-cart.svg") no-repeat 50% 50% / 25px;
					display: block;
				}
				> .hover {
					position: absolute;
					display: block;
					width: 40px;
					height: 40px;
					position: absolute;
					left: 0.8rem;
					background-color: #fff;
					border-radius: 50%;
					overflow: hidden;
					transition: background-color 500ms ease;
					&::after {
						content: "";
						background: url("../img/bg-wave-2.svg") repeat-x;
						background-size: 40px;
						transform: scaleY(0);
						transition: transform 300ms ease, left 800ms ease;
						transform-origin: bottom;
						position: absolute;
						bottom: -5px;
						left: 0;
						width: 160px;
						height: 100%;
					}
				}

				&:hover {
					background-color: $bleu-uf;
					> .hover {
						//background-color: #3ca3bf;
						&::after {
							transform: scaleY(0.8);
							left: -200%;
						}
					}
				}
				&:active {
					> .hover {
						&::after {
							left: -300%;
						}
					}
				}
			}
			.btn.btn-secondary {
				padding: 0.8rem 1rem 0.8rem 1rem;
				border: none;
				background-color: $bleu-uf;
				border-radius: 12px;
				color: white;
				font-weight: 700;
				text-transform: uppercase;
				margin-right: 16px;
				transition: background-color 300ms ease;
				&:hover {
					background-color: #00b2bf;
				}
			}
		}
	}

	&.in {
		opacity: 1;
		.modal-dialog {
			transform: translateX(0);
		}
	}
}
