/**
*
* Page à propos
*
*******/
#cms {
	#content {
		> p:first-child {
			display: none;
		}
	}
}

#cms {
	#content {
		.titre-puce-centre,
		.titre-puce {
			font-family: $ff-secondary;
			text-transform: uppercase;
			padding-left: 45px;
			position: relative;
			display: table;
			margin-left: 0;
			margin-top: 2rem;
			margin-bottom: 1rem;
			@include media-breakpoint-up("lg") {
				font-size: 30px;
				line-height: 34px;
			}
			@include media-breakpoint-down("lg") {
				font-size: 18px;
				line-height: 22px;
			}
			&::before {
				content: "";
				background: url("../img/picto-ceres.png") no-repeat 50% 50% / contain;
				width: 29px;
				height: 37px;
				position: absolute;
				top: 0;
				left: 0;
			}
			h2 {
				font-size: 30px;
			}
		}
		.titre-puce-centre {
			margin-left: auto;
			margin-right: auto;
			text-align: left;
		}
		.mot-introduction {
			text-align: center;
			.signature {
				font-family: $ff-tertiary;
				font-size: 18px;
			}
		}
	}

	#qui-sommes-nous {
		background: url("../img/gold_scale.png") repeat 50% 50% / 40px;
		position: relative;
		> .wpb_column {
			position: unset;
			float: none;
			padding-top: 80px;
			padding-bottom: 80px;
			@include media-breakpoint-between(sm, md) {
				padding-top: 40px;
				padding-bottom: 40px;
			}
			> .vc_column-inner > .wpb_wrapper > .wpb_single_image {
				position: absolute;
				left: 0;
				width: 100%;
				margin-bottom: 0 !important;
				img {
					position: absolute;
				}
				&:first-child {
					top: 0px;
					img {
						top: 0;
					}
				}
				&:last-child {
					bottom: 0px;
					img {
						bottom: 0;
					}
				}
			}
		}
		.titre-puce {
			color: white;
		}
		.col-with-text {
			padding-bottom: 2rem;
			@include media-breakpoint-up("sm") {
				padding-top: 4rem;
			}
			p {
				color: white;
			}
			a {
				text-transform: uppercase;
				color: #fff;
				background-color: #01b3c1;
				font-size: 0.8rem;
				padding-top: 7px;
				padding-bottom: 7px;
				padding-left: 70px;
				padding-right: 20px;
				line-height: 40px;
				height: calc(40px + 7px + 7px);
				border-radius: 8px;
				display: table;
				position: relative;
				transition: background-color 300ms ease;
				margin-top: 3rem;
				&::before {
					content: "";
					position: absolute;
					left: 10px;
					width: 40px;
					height: 40px;
					top: 50%;
					transform: translateY(-50%);
					background-size: contain;
					background-repeat: no-repeat;
					background-image: url(../img/picto-bouton-corail.png);
				}
				&:hover {
					color: #fff;
					text-decoration: none;
					background-color: $bleu-f;
				}
				&:active {
					color: #fff;
				}
			}
		}
		.col-with-images {
			@include media-breakpoint-down("sm") {
				width: 100%;
			}
			@include media-breakpoint-between(sm, md) {
				padding-top: 4rem;
			}
		}
		.col-with-images > .vc_column-inner > .wpb_wrapper {
			position: relative;
			height: 380px;
			@include media-breakpoint-up("lg") {
				height: 480px;
			}
			> .wpb_single_image {
				position: absolute !important;
				top: 0;
				img {
					height: 300px;
					width: auto;
					@include media-breakpoint-up("lg") {
						height: 405px;
					}
				}

				&.image-1 {
					top: 50px;
				}

				&.image-2 {
					left: 150px;
					@include media-breakpoint-up("sm") {
						left: 120px;
					}
					@include media-breakpoint-up("lg") {
						left: 260px;
					}
				}
			}
		}
	}
	#qui-sommes-nous::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
		mix-blend-mode: multiply;
		background-color: #1a345a;
		opacity: 0.95;
	}

	#histoire-ceres {
		.bloc-texte-histoire {
			span {
				color: $bleu-m;
				&::before {
					content: "";
					display: block;
					height: 1rem;
				}
			}
		}

		.a-propos-6-pictos {
			@include media-breakpoint-between(sm, md) {
				@include make-row();
				> .wpb_column {
					@include make-col-ready;
					@include make-col(4);
				}
			}
			.picto-image {
				> .wpb_wrapper {
					height: 80px;
				}
			}
			.picto-texte {
				text-align: center;
				font-family: "Oswald";
				text-transform: uppercase;
				color: $bleu-f;
				margin-bottom: 1rem;
				strong {
					display: block;
					margin-left: auto;
					margin-right: auto;
					font-size: 18px;
					margin-bottom: 0.625rem;
				}
			}
		}
	}
	[class*="list-category-row-"] {
		
		.col-cat {
			position: relative;
			&::after {
				content: "";
				display: block !important;
				padding-bottom: 100%;
			}
			> .vc_column-inner {
				height: calc(100% - 15px);
				position: absolute;
				top: 0px;
				left: 15px;
				right: 15px;
				width: calc(100% - 30px);
			}
		}
		.col-cat > .vc_column-inner {
			background-size: cover;
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: #1a345a;
				mix-blend-mode: multiply;
				opacity: 0.45;
			}

			> .wpb_wrapper {
				position: absolute;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				.titre-cat {
					color: white;
					font-family: $ff-secondary;
					text-transform: uppercase;
					font-size: 21px;
					padding-bottom: 30px;
					padding-top: 60px;
					background: url("../img/vague-titre-cat.svg") no-repeat bottom center /
						30px;
					position: relative;
					&::before {
						content: "";
						width: 80px;
						height: 40px;
						display: block;
						position: absolute;
						top: 0;
						left: 50%;
						transform: translateX(-50%);
						background-size: contain;
						background-repeat: no-repeat;
						background-position: center;
					}

					&.cat-pf::before {
						background-image: url("../img/pico-pf.svg");
					}
					&.cat-fdm::before {
						background-image: url("../img/picto-fm.svg");
					}
					&.cat-ceph::before {
						background-image: url("../img/picto-cepha.svg");
					}
					&.cat-surg::before {
						background-image: url("../img/picto-surg.svg");
					}
					&.cat-vf::before {
						background-image: url("../img/picto-vf.svg");
					}
					&.cat-epdm::before {
						background-image: url("../img/picto-epdm.svg");
					}
					&.cat-na::before {
						background-image: url("../img/picto-na.svg");
					}
				}
			}
		}
		.col-cat.col-newsletter > .vc_column-inner {
			> .wpb_wrapper {
				padding-left: 1.8rem;
				padding-right: 1.8rem;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				> .wpb_content_element {
					margin-bottom: 0 !important;
				}
			}
			&::before {
				background-color: #b4dde2;
				opacity: 1;
				mix-blend-mode: normal;
				z-index: -1;
			}
			.titre {
				color: #0b323b;
				font-size: 21px;
				text-transform: uppercase;
				font-family: $ff-secondary;
				font-weight: 700;
				
			}
			.content {
				font-family: $ff-primary;
				color: #0b323b;
				font-size: 16px;
				@include media-breakpoint-down("sm") {
					font-size: 14px;
					line-height: 14x;
				}
				a {
					font-weight: 700;
					display: table;
					margin-left: auto;
					margin-right: auto;
					text-transform: uppercase;
					font-weight: 700;
					color: #0b323b;
					margin-top: 1.4rem;
					&::before {
						content: ">";
					}
					@include media-breakpoint-down("sm") {
						margin-top: 1rem;
					}
				}	
			}
		}
		
		@include media-breakpoint-between(sm, md) {
			@include make-row();
			> .col-cat {
				@include make-col-ready;
				@include make-col(6);
			}
		}
		@include media-breakpoint-down("md") {
			.col-cat {
				margin-bottom: 30px;
			}
		}
		@include media-breakpoint-down("lg") {
			.titre {
				font-size: 15px !important;
				line-height: 17px;
			}
			.content {
				font-size: 13px !important;
				line-height: 15px;
			}
		}
	}
}

.wave-before-after {
	background: url("../img/gold_scale.png") repeat 50% 50% / 40px;
	position: relative;
	.titre-puce-centre {
		color: white;
		padding-bottom: 3rem;
	}
	> .wpb_column {
		position: unset;
		float: none;
		padding-top: 80px;
		padding-bottom: 80px;
		@include media-breakpoint-between(sm, md) {
			padding-top: 40px;
			padding-bottom: 40px;
		}
		> .vc_column-inner > .wpb_wrapper > .wpb_single_image {
			position: absolute;
			left: 0;
			width: 100%;
			margin-bottom: 0 !important;
			img {
				position: absolute;
			}
			&:first-child {
				top: 0px;
				img {
					top: 0;
				}
			}
			&:last-child {
				bottom: 0px;
				img {
					bottom: 0;
				}
			}
		}
	}
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block !important;
		mix-blend-mode: multiply;
		background-color: #1a345a;
		opacity: 0.95;
	}
	.col-picto {
		.wpb_text_column p {
			text-transform: uppercase;
			color: white;
			text-align: center;
		}
	}
}

#a-propos-contact {
    @include media-breakpoint-up('lg') {
        display: flex;
        align-items: center;
        .vc_col-sm-4 {
            p {
                text-align: right;
            }
        }
    }
	a {
		text-transform: uppercase;
		color: #fff;
		background-color: #01b3c1;
		font-size: 0.8rem;
		padding-top: 7px;
		padding-bottom: 7px;
		padding-left: 70px;
		padding-right: 20px;
		line-height: 40px;
		height: calc(40px + 7px + 7px);
		border-radius: 8px;
		display: inline-block;
		position: relative;
		transition: background-color 300ms ease;
		&::before {
			content: "";
			position: absolute;
			left: 10px;
			width: 40px;
			height: 40px;
			top: 50%;
			transform: translateY(-50%);
			background-size: contain;
			background-repeat: no-repeat;
			background-image: url(../img/picto-bouton-corail.png);
		}
		&:hover {
			color: #fff;
			text-decoration: none;
			background-color: $bleu-f;
		}
		&:active {
			color: #fff;
		}
	}
}
.cms-id-8 {
    .bleu {
        color: #00b2bf;
        font-weight: 700;
        &::before {
            content:'';
            display: block;
            height: 3rem;
        }
    }
}