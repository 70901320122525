// Global
#index #wrapper {
    padding-top: 0;
}

.vc_row {
    .home--pictos-title {
        color: #1e7274;
        font-size: 1rem;
        letter-spacing: -0.1rem;
        text-transform: uppercase;
        margin-top: 2rem;
    }
}

p:empty {
    display: none;
}

.wpb_row,
.wpb_content_element,
ul.wpb_thumbnails-fluid>li,
.last_toggle_el_margin,
.wpb_button {
    margin-bottom: 15px !important;
}

// Editorial
.home--editorial-full-width-image {
    visibility: visible;
    position: relative;
    z-index: 1;
    background-size: 100% 800px;
    background-repeat: no-repeat;

    >div.upb_row_bg {
        min-height: 800px !important;
        max-height: 800px !important;
        background-size: cover !important;
    }

    >div.vc_col-sm-12 {
        float: none;
    }

    &::before {
        content: "" !important;
        width: 100% !important;
        height: 800px;
        background-color: rgba(0, 0, 0, 0.3);
        display: block !important;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 1;
    }

    .home--editorial {
        background-color: #fff;
        padding: 5px;
        box-shadow: 10px 10px 31px 1px rgba(0, 0, 0, 0.35);
        -webkit-box-shadow: 10px 10px 31px 1px rgba(0, 0, 0, 0.35);
        -moz-box-shadow: 10px 10px 31px 1px rgba(0, 0, 0, 0.35);

        @include media-breakpoint-between(sm, md) {
            .vc_col-sm-7 {
                padding-top: 2rem;
            }
        }

        .wpb_single_image.vc_align_left {
            margin: 0 !important;

            @include media-breakpoint-down("sm") {
                >.wpb_wrapper {
                    height: 200px;
                    overflow: hidden;
                    margin-bottom: 1rem;

                    img {
                        width: 100%;
                        height: auto;
                        transform: translateY(-40%);
                    }
                }
            }
        }

        .font-3 {
            color: $bleu-c;
            font-size: 18px;
            margin-bottom: 2rem !important;
        }

        .font-2 {
            color: #000;
            font-weight: normal;
            position: relative;
            text-transform: uppercase;
            line-height: normal;
            display: inline-block;

            &::first-line {
                padding-left: 50px;
            }

            .wpb_wrapper {
                position: relative;
                padding-left: 30px;

                h3:first-child {
                    padding-left: 40px;
                }

                &::before {
                    content: "";
                    display: inline-block;
                    background-image: url(../img/picto-ceres.png);
                    width: 29px;
                    height: 37px;
                    position: absolute;
                    transform: translateY(0px);
                    top: 0px;
                    left: 0px;
                }
            }
        }
    }

    .home--editorial.equal {
        @include media-breakpoint-between(sm, md) {
            @include make-row;

            .wpb_column {
                @include make-col-ready;
                @include make-col(12);

                &:first-child {
                    .wpb_single_image>.wpb_wrapper {
                        height: 200px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: 50% 50%;
                        }
                    }
                }
            }
        }
    }
}

.home--editorial-button {
    a {
        text-transform: uppercase;
        color: #fff;
        background-color: #01b3c1;
        font-size: 0.8rem;
        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 70px;
        padding-right: 20px;
        line-height: 40px;
        height: calc(40px + 7px + 7px);
        border-radius: 8px;
        display: inline-block;
        position: relative;
        transition: background-color 300ms ease;

        &::before {
            content: "";
            position: absolute;
            left: 10px;
            width: 40px;
            height: 40px;
            top: 50%;
            transform: translateY(-50%);
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url(../img/picto-bouton-corail.png);
        }

        &:hover {
            color: #fff;
            text-decoration: none;
            background-color: $bleu-f;
        }

        &:active {
            color: #fff;
        }
    }
}

.home--editorial-slogan {
    color: #fff;
    font-size: 1.25rem;
    //font-style: italic;
    font-weight: 600;
    margin-bottom: 1rem;
    text-shadow: 4px 3px 9px rgba(0, 0, 0, 0.51);
    font-family: $ff-tertiary;
    padding-top: 5rem;

    @include media-breakpoint-up("md") {
        padding-top: 13rem;
    }
}

.home--editorial-h3 {
    padding-bottom: 5rem;

    @include media-breakpoint-up("md") {
        padding-bottom: 13rem;
    }

    h1 {
        font-family: $ff-secondary;
        color: #fff;
        font-weight: 400;
        text-transform: uppercase;
        margin-top: 1rem;
        text-shadow: 4px 3px 9px rgba(0, 0, 0, 0.51);
        font-size: 1.95rem;
        line-height: 1.1;

        @include media-breakpoint-up("md") {
            font-size: 2.75rem;
            line-height: 3rem;
        }

        @include media-breakpoint-down("md") {
            font-size: 1.75rem;
            line-height: 2rem;
        }

    }
}

// 3 simple steps
.home--3-simple-steps-title {
    color: #fff;
    font-size: 1.75rem;
    font-family: $ff-secondary;
    line-height: 2rem;
    padding: 0 0 0 50px;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;

    span {
        font-size: 1.25rem;
        line-height: 1.35rem;
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 29px;
        height: 37px;
        top: 0%;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../img/picto-ceres.png);
    }

    @include media-breakpoint-down("md") {
        font-size: 1.5rem;

        span {
            font-size: 1rem;
            line-height: 1rem;
        }
    }
}

.home--3-simple-steps-text {
    color: #fff;
    font-size: 0.75rem;
}

.home--3-simple-steps-item-title {
    color: #00b2bf;
    font-size: 1rem;
    text-transform: uppercase;

    span {
        font-size: 0.75rem;
    }
}

// Suggestions of the moment
.home--suggestion-moment {

    .promotions,
    .news,
    .register {
        transition: background-position 600ms ease;
        height: 260px;

        .title {
            color: #02343f;
            font-size: 1.75rem;
            line-height: 1.75rem;
            font-weight: 400;
            text-transform: uppercase;
            font-family: $ff-secondary;

            @include media-breakpoint-between(sm, md) {
                font-size: 1.5rem;
            }
        }

        .subtitle {
            color: #02343f;
            text-transform: uppercase;
        }

        &:hover {
            background-position: -30px 0px;
        }
    }

    .promotions {
        width: auto;
        padding-top: 50px;
        padding-bottom: 40px;
        padding-left: 135px;
        margin-top: 0;
        margin-bottom: 0;
        background: url(../img/suggestion-promotions.png) #2a8195 -30px 30px no-repeat;

        .text {
            color: #02343f;
            font-size: 0.75rem;
            line-height: 0.9rem;
            height: 60px;
            padding-right: 10px;
            margin: 7px 0 10px 0;
        }

        a {
            color: #02343f;
            font-size: 0.9rem;
            font-weight: 600;
            text-decoration: none;
            text-transform: uppercase;

            &:hover {
                color: #02343f;
                text-decoration: none;
            }
        }
    }

    .news {
        width: auto;
        padding-top: 50px;
        padding-bottom: 40px;
        padding-left: 135px;
        background: url(../img/suggestion-nouveautes.png) #3ca4c1 -30px 30px no-repeat;

        .text {
            color: #184143;
            font-size: 0.75rem;
            line-height: 0.9rem;
            height: 60px;
            padding-right: 10px;
            margin: 7px 0 10px 0;
        }

        a {
            color: #184143;
            font-size: 0.9rem;
            font-weight: 600;
            text-decoration: none;
            text-transform: uppercase;

            &:hover {
                color: #184143;
                text-decoration: none;
            }
        }
    }

    .register {
        width: auto;
        padding-top: 50px;
        padding-bottom: 40px;
        padding-left: 135px;
        background: url(../img/suggestion-inscription.png) #b4dde3 -30px 30px no-repeat;

        .text {
            color: #237485;
            font-size: 0.75rem;
            line-height: 0.9rem;
            height: 60px;
            padding-right: 10px;
            margin: 7px 0 10px 0;
        }

        a {
            color: #237485;
            font-size: 0.9rem;
            font-weight: 600;
            text-decoration: none;
            text-transform: uppercase;

            &:hover {
                color: #237485;
                text-decoration: none;
            }
        }
    }

    .promotions,
    .news,
    .register {
        @include media-breakpoint-between(sm, md) {
            padding: 1rem;
            background-image: none;
            height: auto;
        }
    }
}

.home--suggestion-moment-title {
    position: relative;
    color: #fff;
    font-size: 1.75rem;
    margin-bottom: 0;
    padding-left: calc(15px + 55px);
    padding-bottom: 3rem;
    text-transform: uppercase;
    font-family: $ff-secondary;

    &::before {
        content: "";
        position: absolute;
        left: 15px;
        width: 29px;
        height: 37px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../img/picto-ceres.png);
    }

    @include media-breakpoint-down("md") {
        font-size: 1.5rem;
    }
}

// Fait partie de "Suggestion" mais devrait être à part (en raison du fond d'écran que partagent Suggestion et Product)
.home--suggestion-product {
    img {
        position: relative;
        border: 10px solid #fff;
    }

    .promo {
        &::before {
            content: "Promo !";
            position: absolute;
            z-index: 1;
            top: 10px;
            left: calc(15px + 10px);
            color: #fff;
            background-color: #00b2bf;
            font-size: 0.9rem;
            font-weight: 600;
            text-transform: uppercase;
            padding: 2px 10px;
            margin-bottom: 0;
        }
    }

    .surgele {
        &::after {
            content: "";
            position: absolute;
            right: calc(15px + 10px);
            top: 10px;
            width: 25px;
            height: 25px;
            background-repeat: no-repeat;
            background-image: url(../img/picto-flocon-surgeles.png);
        }
    }

    .title {
        color: #fff;
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: 600;
        margin-bottom: 0 !important;
    }

    .info {
        color: #fff;
        font-size: 0.85rem;
        line-height: 1rem;
        margin-bottom: 0;
    }
}

.suggestion-du-moment {
    position: relative;
    overflow: hidden;

    .product-grid-wrapper {
        @extend .container;

        .products {
            @include make-row();

            >div {
                @include make-col-ready();

                @include media-breakpoint-up("md") {
                    @include make-col(4);
                    padding-bottom: 30px;
                }

                @include media-breakpoint-up("lg") {
                    @include make-col(3);
                    padding-bottom: 30px;
                }

                color: white;

                .product-title a {
                    color: white;
                }
            }
        }
    }
}

.vc_custom_1616754120461,
.vc_custom_1616754128696,
.vc_custom_1616505021358,
.vc_custom_1616505050777 {
    >.wpb_wrapper {
        position: relative;

        >img {
            height: 100%;

            @include media-breakpoint-down("sm") {
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        @include media-breakpoint-down("sm") {
            height: 50px;
        }
    }
}

// Pictos x6
.home--6pictos {
    margin-bottom: 3rem !important;

    .picto {
        text-align: center;
        color: $bleu-f;
        font-size: 1rem;
        font-weight: 400;
        text-transform: uppercase;
        margin-top: 0;
        font-family: "Oswald";
        font-size: 13px;

        img {
            width: 75px;
            height: auto;
            margin-top: 0;
        }
    }

    @include media-breakpoint-down("sm") {
        .vc_col-sm-2 {
            width: 50%;
            float: left;
        }
    }
}

[class^="home--categories-image"] {
    position: relative;

    .left-part {
        color: #fff;
        font-size: 1.25rem;
        line-height: 1.5rem;
        padding-top: calc(30px + 40px + 40px);
        text-decoration: none;
        text-transform: uppercase;
        position: relative;
        font-family: $ff-secondary;
    }

    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .image {
        transition: width 300ms ease;
    }

    &:hover {
        .image {
            width: 100%;
        }
    }
}

[class^="home--categories-"] {
    width: auto;
    height: 340px;
    background-size: cover;
    background-position: 50% 50%;

    .image {
        width: 160px;
        height: 100%;
        text-align: center;
        background: rgba(0, 0, 0, 0.375);
    }

    .left-part {
        &::before {
            content: "";
            position: absolute;
            width: 80px;
            height: 40px;
            top: 40px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            background-size: contain;
            background-repeat: no-repeat;
        }

        &::after {
            content: "";
            position: absolute;
            width: 80px;
            height: 40px;
            bottom: -40px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url(../img/picto-after-categorie.png);
        }
    }
}

.home--categories {
    &-image1 {
        background-image: url("../img/PoissonsFraisHP.jpg");

        .left-part::before {
            background-image: url(../img/picto-cat-poissons-frais.png);
        }
    }

    &-image2 {
        background-image: url("../img/FruitsdeMer.jpg");

        .left-part::before {
            background-image: url(../img/picto-cat-coquillages.png);
        }
    }

    &-image3 {
        background-image: url("../img/Cephalopodes.jpg");

        .left-part::before {
            background-image: url(../img/picto-cat-cephalopodes.png);
        }
    }

    &-image4 {
        background-image: url("../img/SurgelesHP.jpg");

        .left-part::before {
            background-image: url(../img/picto-cat-surgeles.png);
        }
    }

    &-image5 {
        background-image: url("../img/Viande.jpg");

        .left-part::before {
            background-image: url(../img/picto-cat-viandes.png);
        }
    }

    &-image6 {
        background-image: url("../img/Epicerie.jpg");

        .left-part::before {
            background-image: url(../img/picto-cat-epicerie.png);
        }
    }

    &-image7 {
        background-image: url("../img/NonAlimentaire.jpg");

        .left-part::before {
            background-image: url(../img/picto-cat-non-alimentaire.png);
        }
    }
}

.home--categories-text {
    font-size: 0.9rem;
    line-height: 1.15rem;
    color: #000;
    text-align: justify;
}

// Fish and news
.home--fish-and-news-title {
    color: #000;
    font-size: 2rem;
    font-weight: 600;
    padding: 0 0 0 50px;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 29px;
        height: 37px;
        top: 50%;
        transform: translateY(-50%);
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../img/picto-ceres.png);
    }

    &:hover {
        color: #000;
        text-decoration: none;
    }

    &:active {
        color: #000;
    }
}

.home--fish-and-news-edito {
    color: #000;
    font-size: 0.9rem;
    line-height: 1.15rem;
    text-align: justify;
}

.home--fish-and-news-chefs {
    img {
        max-height: 110px;
        line-height: 110px;
    }
}

.home--fish-and-news-text1 {
    color: #000;
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 600;
    padding-left: 5%;
    padding-right: 5%;
    text-align: center;
    text-transform: uppercase;
}

.home--fish-and-news-text2 {
    color: #000;
    font-size: 0.85rem;
    padding-left: 5%;
    padding-right: 5%;
    text-align: center;
}

.home--fish-and-news-text3 {
    color: #000;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
}

.home--fish-and-news-article {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;

    .border {
        border: 2px solid #01b3c1;
        border-top: 0;
        padding: 30px 25px;
    }

    .date {
        color: #000;
        font-size: 0.9rem;
    }

    .title {
        color: #000;
        font-size: 1.05rem;
        line-height: 1.15rem;
        letter-spacing: -0.0625rem;
    }

    .text {
        color: #000;
        font-size: 0.9rem;
    }

    .link {
        margin-top: 0;
        margin-bottom: 0;

        a {
            color: #01b3c1;
            text-decoration: none;
            text-transform: uppercase;
            padding-left: 20px;
            padding-right: 20px;
            position: relative;

            &:hover {
                color: #01b3c1;
                text-decoration: none;
            }

            &::before {
                content: "";
                position: absolute;
                left: 0;
                width: 12px;
                height: 7px;
                top: 50%;
                transform: translateY(-50%);
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url(../img/lire-la-suite-before-after.svg);
            }

            &::after {
                content: "";
                position: absolute;
                right: 0;
                width: 12px;
                height: 7px;
                top: 50%;
                transform: translateY(-50%);
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url(../img/lire-la-suite-before-after.svg);
            }
        }
    }
}

.home--fish-and-news-button {
    text-transform: uppercase;
    color: #fff;
    background-color: #01b3c1;
    font-size: 0.8rem;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 70px;
    padding-right: 20px;
    line-height: 40px;
    height: calc(40px + 7px + 7px);
    border-radius: 8px;
    display: inline-block;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 10px;
        width: 40px;
        height: 40px;
        top: 50%;
        transform: translateY(-50%);
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../img/picto-bouton-coquillage.png);
    }

    &:hover {
        color: #fff;
        text-decoration: none;
    }

    &:active {
        color: #fff;
    }
}

.titre-blog {
    .flex-title {
        @include media-breakpoint-up("sm") {
            display: flex;
        }

        a {
            margin-left: auto;
            background-color: #00b2bf;
            border: none;
            text-transform: uppercase;
            align-items: center;
            display: inline-flex;
            padding: 0.8rem 1rem 0.8rem 3.8rem;
            border-radius: 12px;
            color: white;
            font-weight: 700;
            justify-content: space-around;
            position: relative;
            transition: background-color 300ms ease;

            @include media-breakpoint-up("md") {
                display: flex;
            }

            &::before {
                content: "";
                height: 40px;
                width: 40px;
                background: #fff url("../img/btn-news.svg") no-repeat 50% 50% / 26px;
                border-radius: 50%;
                position: absolute;
                top: 4px;
                left: 12px;
                transition: transform 300ms ease;
                transform-origin: 50% 50%;
            }

            &:hover {
                background-color: $bleu-f;

                &:before {
                    //background-color: $bleu-uf;
                    transform: rotate(360deg);
                }
            }
        }
    }

    .titre_puce_blog {
        font-family: $ff-secondary;
        font-size: 30px;
        text-transform: uppercase;
        padding-left: 47px;
        position: relative;
        display: table;
        margin-left: 0;
        margin-right: auto;

        &::before {
            content: "";
            background: url("../img/picto-ceres.png") no-repeat 50% 50% / contain;
            width: 29px;
            height: 37px;
            position: absolute;
            top: 0;
            left: 0;
        }

        @include media-breakpoint-down("md") {
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 2rem;
        }
    }
}

#index .prestablog,
#grid-blog {
    article {
        .article {
            height: 100%;
            position: relative;
            padding-bottom: 3rem;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: stretch;

            &::before {
                content: "";
                position: absolute;
                top: 1.5rem;
                left: 1.5rem;
                width: calc(100% - 3rem);
                height: calc(100% - 3rem);
                border: 2px solid $bleu-c;
                z-index: 0;
            }

            &__date,
            &__titre,
            &__excerpt {
                padding-left: 4rem;
                padding-right: 4rem;
                text-align: center;
                position: relative;
                z-index: 2;
            }

            &__thumb {
                position: relative;
                z-index: -1;

                a {
                    display: block;
                    height: 200px;
                    width: 100%;
                    position: relative;
                    overflow: hidden;
                }

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                }
            }

            &__date {
                padding-top: 1.5rem;
                font-weight: 400;
                font-size: 13px;
                padding-bottom: 0.5rem;
            }

            &__titre {
                font-family: $ff-secondary;
                font-weight: 700;
                font-size: 18px;
                line-height: 1.1;
                margin-bottom: 0.5rem;

                a {
                    color: #000;

                    &:hover {
                        color: $bleu-c;
                    }
                }
            }

            &__excerpt {
                font-size: 13px;
            }

            .article__link {
                a::before {
                    left: -18px !important;
                }

                a::after {
                    right: -18px !important;
                }

                &:hover {
                    a::before {
                        left: -25px !important;
                    }

                    a::after {
                        right: -25px !important;
                    }
                }
            }

            &__link {
                text-align: center;
                margin-top: auto;
                width: auto;

                a {
                    display: inline-block;
                    position: relative;
                    text-transform: uppercase;
                    color: $bleu-c;

                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        width: 10px;
                        height: 4px;
                        background: url("../img/readmore-prestablog.svg");
                        top: 50%;
                        transform: translateY(-50%);
                        transition: right 300ms ease, left 300ms ease;
                    }
                }
            }
        }
    }

    @include media-breakpoint-between(sm, md) {
        .col-md-4 {
            @include make-col(6);
            @include make-col-offset(3);
        }
    }
}

#references {
    padding-top: 4rem;
    padding-bottom: 4rem;

    h2 {
        font-family: $ff-secondary;
        font-size: 24px;
        text-transform: uppercase;

        @include media-breakpoint-down("md") {
            font-size: 20px;
            line-height: 24px
        }
    }

    .sous-titre {
        font-family: $ff-secondary;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 700;
    }

    .logotypes {
        text-align: center;

        img {
            display: inline-block;
            margin: 0 10px !important;
        }
    }
}