[class^=btn-default-], %btn-default, .btn-primary {
	background-color: #00b2bf;
	border: none;
	text-transform: uppercase;
	display: inline-flex;
	align-items: center;
	padding: 0.8rem 1rem 0.8rem 1rem;
	border-radius: 12px;
	color: white;
	font-weight: 700;
	justify-content: space-around;
	position: relative;
	transition: background-color 300ms ease;
    &:hover {
        background-color: $bleu-uf;
        color: white;
    }
}
