#hero-image {
	background-size: cover;
	background-position: 50% 50%;
	height: 300px;
	background-image: url("../img/hero-fish-and-news.jpg");
	position: relative;
	.title {
		margin-bottom: 0 !important;
		padding: 1rem 0;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0.5);
		h1 {
			text-align: center;
			font-size: 25px;
			color: white;
			font-family: $ff-secondary;
			text-align: center;
			font-weight: 400;
			text-transform: uppercase;
			line-height: 1.2;
			margin-bottom: 0;
			@include media-breakpoint-up("lg") {
				font-size: 35px;
			}
		}
	}
}
.introduction-blog {
	h2 {
		font-family: $ff-secondary;
		font-size: 25px;
		text-transform: uppercase;
		padding-left: 45px;
		position: relative;
		display: table;
		margin-left: auto;
		margin-right: auto;
		margin-top: 2rem;
		margin-bottom: 1rem;
		@include media-breakpoint-up("lg") {
			font-size: 30px;
		}
		@include media-breakpoint-down("sm") {
			text-align: left !important;
			margin-left: 30px;
		}
		&::before {
			content: "";
			background: url("../img/picto-ceres.png") no-repeat 50% 50% / contain;
			width: 29px;
			height: 37px;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

#grid-blog {
	@include media-breakpoint-up("sm") {
		@include make-row;
		article {
			@include make-col-ready;
		}
	}
	@include media-breakpoint-up("md") {
		article {
			@include make-col(4);
		}
	}
	@include media-breakpoint-between(sm, md) {
		article {
			@include make-col(6);
		}
	}
}

#prestablogfont {
	h2 {
		font-family: $ff-secondary;
		font-size: 20px;
		text-transform: uppercase;
		padding-left: 45px;
		position: relative;
		display: table;
		margin-left: 0;
		margin-top: 2rem;
		margin-bottom: 1rem;
		@include media-breakpoint-up("lg") {
			font-size: 30px;
		}
		&::before {
			content: "";
			background: url("../img/picto-ceres.png") no-repeat 50% 50% / contain;
			width: 29px;
			height: 37px;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}
a.all-news {
	margin-left: auto;
	background-color: #00b2bf;
	border: none;
	text-transform: uppercase;
	align-items: center;
	display: inline-flex;
	padding: 0.8rem 1rem 0.8rem 3.8rem;
	border-radius: 12px;
	color: white;
	font-weight: 700;
	justify-content: space-around;
	position: relative;
	transition: background-color 300ms ease;
	&::before {
		content: "";
		height: 40px;
		width: 40px;
		background: #fff url("../img/btn-news.svg") no-repeat 50% 50% / 26px;
		border-radius: 50%;
		position: absolute;
		top: 4px;
		left: 12px;
		transition: transform 300ms ease;
		transform-origin: 50% 50%;
	}

	&:hover {
		background-color: $bleu-f;
		&:before {
			//background-color: $bleu-uf;
			transform: rotate(360deg);
		}
	}
    margin-bottom: 4rem;
}

.social-sharing {
	display: flex;
	align-items: center;
	margin-bottom: 1.5rem;
	> span {
		margin-right: 8px;
	}
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: flex;
		align-items: center;
		li {
			display: inline-block;
			&:not(:last-child) {
				margin-right: 12px;
			}
			a {
				border-radius: 50%;
				width: 25px;
				height: 25px;
				display: block;
				background-size: 20px;
				background-repeat: no-repeat;
				background-position: 50% 50%;
                transition: background-color 300ms ease;
				&:hover {
					background-color: $bleu-c;
				}
			}
		}
		li.facebook {
			a {
				background-image: url("../img/facebook.svg");
			}
		}
		li.twitter {
			a {
				background-image: url("../img/twitter.svg");
			}
		}
		li.pinterest {
			a {
				background-image: url("../img/pinterest.svg");
			}
		}
	}
}
