.product-miniature.js-product-miniature {
	@include media-breakpoint-down("sm") {
		margin-bottom: 20px;
	}
	.product-thumbnail {
		&:hover {
			.btn {
				top: 50%;
				transform: translate(-50%, -50%);
			}
			.hover {
				transform: scaleY(1);
				animation: 3s linear 0s infinite running wave;
			}
		}
	}
	.thumbnail {
		padding: 1rem;
		box-shadow: 6px 6px 6px #00000060;
		display: block;
		margin-bottom: 1.5rem;
		background-color: #fff;
		position: relative;
		overflow: hidden;
		.hover {
			display: block;
			background-color: #9aebed;
			height: 70%;
			width: 200%;
			position: absolute;
			left: 0;
			bottom: 0;
			opacity: 0.9;
			transition: transform 600ms ease;
			transform: scaleY(0);
			transform-origin: bottom;
			z-index: 999;
			&::before {
				content: "";
				width: 100%;
				left: 0;
				position: absolute;
				width: 100%;
				height: 40px;
				top: -30px;
				background: url("../img/bg-wave-2.svg") repeat-x top 0 left 0 / 40px;
			}
		}
		.btn {
			z-index: 999;
			position: absolute;
			top: 110%;
			left: 50%;
			transform: translate(-50%, 0);
			background-color: $bleu-m;
			color: white;
			text-transform: uppercase;
			padding: 8px 10px 8px 30px;
			border-radius: 8px;
			font-size: 15px;
			width: 80%;
			text-align: center;
			background: $bleu-uf url("../img/eyes.svg") no-repeat left 18px top 50% /
				20px;
			box-shadow: 0 0 6px rgba(#000, 0.7);
			transition: all 600ms 150ms ease;
		}
		.product-flags {
			list-style-type: none;
			margin: 0;
			padding: 0;
			position: absolute;
			top: 1rem;
			left: 1rem;
			li {
				&.new {
					background-color: #e5bb07;
					color: white;
					padding: 4px 18px;
					font-family: $ff-secondary;
					text-transform: uppercase;
					border-radius: 30px;
					font-size: 12px;
				}
			}
		}
		.availability {
			position: absolute;
			bottom: 1rem;
			left: 1rem;
			text-transform: uppercase;
			font-size: 12px;
			font-weight: 700;
			background-color: transparent !important;
			&.available {
				color: #7ab043;
				&.on-demand {
					color: $orange;
				}
			}
			&.unavailable {
				color: red;
			}
		}
	}

	.product-description {
		.product-title {
			font-size: 18px;
			font-weight: 700;
			a {
				color: #000;
			}
		}
	}
	.product-description {
		padding-right: 54px;
		position: relative;
		.product-price-and-shipping {
			font-size: 20px;
			font-weight: 700;
		}
		.quick-view {
			position: absolute;
			right: 0;
			top: 0;
			display: block;
			width: 44px;
			height: 44px;
			border: 1px solid $bleu-m;
			border-radius: 50%;
			z-index: 1;
			&::after {
				content: "";
				position: absolute;
				background: url("../img/eyes.svg") no-repeat 50% 50% / 30px;
				width: 100%;
				height: 100%;
			}
			&::before {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				z-index: -1;
				width: 1px;
				height: 1px;
				border: 1px solid $bleu-c;
				transform: translate(-50%, -50%);
				display: block;
				border-radius: 50%;
				overflow: hidden;
				transition: border 500ms ease;
			}
			&:hover {
				&::before {
					border-width: 20px;
				}
			}
		}
	}
}
@keyframes wave {
	0% {
		left: 0;
	}
	100% {
		left: -100%;
	}
}
